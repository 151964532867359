// App Version
export const APP_VERSION = process.env.REACT_APP_APP_VERSION;

// API Base URL
export const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

// SOCKET API URL
export const SOCKET_API_URL = process.env.REACT_APP_SOCKET_API_URL;

// CDN Base URL
export const CDN_BASE_URL = process.env.REACT_APP_CDN_BASE_URL;

// Marketing Base URL
export const MARKETING_BASE_URL = process.env.REACT_APP_MARKETING_BASE_URL;

// App Name
export const APP_NAME = process.env.REACT_APP_APP_NAME;

// Facebook APP ID
export const FB_APP_ID = process.env.REACT_APP_FB_APP_ID;

// Google Maps API Key
export const GOOGLE_MAPS_API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;

// Google OAuth Client ID
export const GOOGLE_OAUTH_CLIENT_ID = process.env.REACT_APP_GOOGLE_OAUTH_CLIENT_ID;

// Multi Locale Initial Values
export const MLInitialValues = { en: "", fr: "", ru: "" };

// Image Placeholders
export const PLACEHOLDERS = {
  thumbnail: "/media/avatars/blank.png",
};

// SENTRY_URL
export const SENTRY_URL = "https://dbffc01af27b488ba8e86a663592f177@o1316113.ingest.sentry.io/4503948504793088";

export const FB_PERMISSIONS = {
  PAGE: [
    "pages_show_list",
    "pages_manage_metadata",
    "pages_read_engagement",
    "pages_messaging",
    "pages_manage_ads",
    "leads_retrieval",
    "instagram_basic",
    "instagram_manage_messages",
    "ads_management",
  ],
  BUSINESS: ["business_management"],
};

// Google Required Scopes
export const GOOGLE_REQUIRED_SCOPES = ["https://www.googleapis.com/auth/business.manage", "https://www.googleapis.com/auth/adwords"];

// Desktop Notification Codes
export const DESKTOP_NOTIFICATION_CODES = [
  "NEW.MESSAGE",
  "NEW.LEAD",
  "LEAD.ASSIGNED",
  "LEAD.ASSIGNED_GROUP",
  "FORM.SUBMITTED",
  "LEAD.UPDATED",
  "NEW.DOCUMENT",
  "NEW.TRANSACTION",
  "NEW.REMINDER",
  "MESSAGE.DELIVERY",
  "QUERY.SELECTION.COMPLETED",
];

export const WORKING_HOURS = {
  sat: { enabled: false, start: "09:00", end: "17:00" },
  sun: { enabled: false, start: "09:00", end: "17:00" },
  mon: { enabled: true, start: "09:00", end: "17:00" },
  tue: { enabled: true, start: "09:00", end: "17:00" },
  wed: { enabled: true, start: "09:00", end: "17:00" },
  thu: { enabled: true, start: "09:00", end: "17:00" },
  fri: { enabled: true, start: "09:00", end: "17:00" },
};

export const HIDDEN_TOAST_ERROR_CODES = [401];
