import { QueryClient, QueryClientProvider } from "react-query";
import { AppConfigProvider } from "./context/AppContext";
import I18nProvider from "./i18n/I18nProvider";
import { AuthProvider } from "./context/AuthContext";
import AppRoutes from "./routes/AppRoutes";
import { ToastContainer } from "react-toastify";
import { AppLayoutProvider } from "./context/AppLayoutContext";
import { GoogleOAuthProvider } from "@react-oauth/google";

// Global Styles
import "./index.css";
import "react-toastify/dist/ReactToastify.css";
import facebookService from "./services/facebook.service";
import { GOOGLE_OAUTH_CLIENT_ID } from "./config";

// Facebook SDK
facebookService.init();

// Google SDK

export default function App() {
  const queryClient = new QueryClient();
  return (
    <QueryClientProvider client={queryClient}>
      <AuthProvider>
        <GoogleOAuthProvider clientId={GOOGLE_OAUTH_CLIENT_ID}>
          <AppConfigProvider>
            <I18nProvider>
              <AppLayoutProvider>
                <AppRoutes />
              </AppLayoutProvider>
              <ToastContainer />
            </I18nProvider>
          </AppConfigProvider>
        </GoogleOAuthProvider>
      </AuthProvider>
    </QueryClientProvider>
  );
}
