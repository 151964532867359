import AppButton from "@components/AppButton";
import ENDPOINTS from "@config/endpoints";
import { AuthContext } from "@context/AuthContext";
import axios from "axios";
import { Field, Formik } from "formik";
import queryString from "query-string";
import { useContext, useEffect, useState } from "react";
import { useMutation } from "react-query";
import { useLocation, useNavigate } from "react-router-dom";
import { AppInput, RowInput } from "../../components/form";

export default function LoginPage() {
  const [error, setError] = useState(null);
  const { login } = useContext(AuthContext);

  const navigate = useNavigate();

  const URLParams = queryString.parse(useLocation().search);

  useEffect(() => {
    if (URLParams.token) login(URLParams?.token);
  }, [URLParams.token]);

  // Get Public Assets
  const GPM = useMutation(() => axios.get(`${ENDPOINTS.public}/${window.location.href.split("/")[2]}`));

  useEffect(() => {
    GPM.mutate();
  }, []);

  // Login Mutation
  const LDM = useMutation((values) => axios.post(`${ENDPOINTS.app.auth}/login`, values), {
    onSuccess: (response) => {
      if (response?.token) login(response?.token);
    },
    onError: (error) => {
      setError(error);
    },
  });

  return (
    <div className="flex h-screen flex-col justify-center bg-gray-100 items-center">
      <div className="flex flex-col border rounded-sm shadow-md p-8 bg-white w-full lg:w-96">
        <img className="h-12 w-auto mb-8 object-contain" src={`${ENDPOINTS.cdn}/public/medias/${GPM.data?.logo}`} />
        <span className="text-center text-xl tracking-wide uppercase text-gray-500 mb-8">Sign In</span>
        <Formik initialValues={{ email: "", password: "" }} onSubmit={LDM.mutate}>
          {({ handleSubmit }) => (
            <form onSubmit={handleSubmit} noValidate>
              <div className="flex flex-col">
                <Field required type="text" name="email" component={RowInput} FormElement={AppInput} placeholder="Email" className="mb-2" />
                <Field component={RowInput} FormElement={AppInput} required name="password" type="password" placeholder="Password" className="mb-6" />
                <AppButton mode="primary" title="BTN.LOGIN" loading={LDM.isLoading} />
              </div>
            </form>
          )}
        </Formik>
      </div>
    </div>
  );
}
