import queryString from "query-string";
import { useContext, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { AuthContext } from "src/context/AuthContext";

export default function LoggedInAuthPage() {
  const { login, token } = useContext(AuthContext);
  const navigate = useNavigate();
  const URLParmas = queryString.parse(useLocation().search);
  useEffect(() => {
    if (URLParmas.token) {
      if (token !== URLParmas.token) login(URLParmas.token);
      else navigate("/dashboard");
    }
    return navigate("/dashboard");
  }, [URLParmas.token]);
}
