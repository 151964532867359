import { PLACEHOLDERS } from "@config";
import { AuthContext } from "@context/AuthContext";
import { useContext } from "react";
import MediaWrapper from "./MediaWrapper";

/**
 * Limited Access Media
 */

export default function ProtectedMedia({ mediaID, mime = "image/", ...props }) {
  const { makeCDNURL } = useContext(AuthContext);
  const url = mediaID ? makeCDNURL(mediaID) : PLACEHOLDERS.thumbnail;
  return <MediaWrapper mime={mime} url={url} {...props} />;
}
