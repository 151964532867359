import { useIntl } from "react-intl";
import Select from "react-select";
export default function AppSelect({ name, value, onChange, multiple = false, options = [], loading = false, required = false, locale = false, ...props }) {
  /** */

  const intl = useIntl();

  const customStyles = {
    control: (props) => ({
      ...props,
      border: "1px solid #e4e6ef",
      borderRadius: 0,
    }),
  };

  const _options = (props?.placeholder ? [{ label: props.placeholder, value: undefined }, ...options] : options)?.map(({ label, value }) =>
    locale ? { label: intl.formatMessage({ id: label ?? "-" }), value } : { label, value }
  );

  return (
    <Select
      className="flex-1"
      styles={customStyles}
      isDisabled={props.disabled}
      instanceId={name}
      options={_options}
      isOptionDisabled={required ? ({ value }) => !Boolean(value) : undefined}
      onChange={(event) => (multiple ? onChange(event?.map(({ value }) => value)) : onChange(event?.value))}
      value={multiple ? _options.filter((option) => value?.includes(option.value)) : _options?.find((option) => option.value === value) ?? 0}
      isLoading={loading}
      isMulti={multiple}
      {...props}
    />
  );
}
