import RouteContainer from "@components/core/RouteContainer";
import { AuthContext } from "@context/AuthContext";
import { lazy, useContext } from "react";
const CompanyDashboard = lazy(() => import("./CompanyDashboard"));
const AgentDashboard = lazy(() => import("./AgentDashboard"));

export default function DashboardPage() {
  const { user } = useContext(AuthContext);
  const paths = [
    {
      path: "/",
      title: "Dashboard",
      element: user.type === "company" || user.dashboard === "company" ? <CompanyDashboard /> : <AgentDashboard />,
    },
  ];
  return <RouteContainer paths={paths} />;
}
