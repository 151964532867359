import { AppLayoutContext } from "@context/AppLayoutContext";
import toast from "@helpers/toast";
import { GoogleMap, Marker, StandaloneSearchBox } from "@react-google-maps/api";
import { useContext, useMemo, useState } from "react";
import { useIntl } from "react-intl";
import AppModal from "../../AppModal";
import CrudButtons from "../../CrudButtons";
import Spinner from "../../Spinner";
import "./GoogleMapModal.css";
export default function GoogleMapModal({ onClose, onChange, initial = [41.0082, 28.9784] }) {
  const [selected, setSelected] = useState({ coordinates: initial });
  const [searchBox, setSearchBox] = useState(undefined);
  const intl = useIntl();
  const {
    GoogleMap: { isReady },
  } = useContext(AppLayoutContext);

  // Handle Select Place
  const handlePlaceChanged = () => {
    if (!searchBox.getPlaces() || searchBox.getPlaces()?.length === 0) return toast.warning("Select One Place");
    const place = searchBox?.getPlaces()?.at(0);
    const {
      formatted_address,
      name,
      geometry: { location },
      photos,
      place_id,
    } = place;

    return setSelected({
      placeID: place_id,
      name,
      address: formatted_address,
      coordinates: [location.lat(), location.lng()],
      photos: photos?.map((p) => p.getUrl()),
    });
  };

  const handleSubmit = () => {
    if (!selected) return toast.warning("Select A Place");
    onClose();
    return onChange(selected);
  };

  const renderGoogleMap = useMemo(
    () => (
      <GoogleMap
        center={{ lat: selected.coordinates[0], lng: selected.coordinates[1] }}
        zoom={15}
        mapContainerStyle={{ width: "100%", height: "100%" }}
        options={{
          zoomControl: false,
          streetViewControl: false,
          mapTypeControl: false,
          fullscreenControl: false,
        }}
        //   onLoad={(map) => setMap(map)}
      >
        <Marker position={{ lat: selected.coordinates[0], lng: selected.coordinates[1] }} />
      </GoogleMap>
    ),
    [selected]
  );

  if (!isReady) return <Spinner />;

  return (
    <AppModal
      centered
      show
      onHide={onClose}
      header={intl.formatMessage({ id: "T.SELECT_LOCATION" })}
      body={
        <div className="flex flex-col space-y-2">
          <StandaloneSearchBox onLoad={(ref) => setSearchBox(ref)} onPlacesChanged={handlePlaceChanged}>
            <input className="form" type="text" placeholder="Search" />
          </StandaloneSearchBox>
          <div className="w-full h-96 rounded-sm overflow-hidden">{renderGoogleMap}</div>
        </div>
      }
      footer={<CrudButtons onSubmit={handleSubmit} onCancell={onClose} />}
    />
  );
}
