import "./AppTooltip.css";
export default function AppTooltip({ children, tooltip }) {
  return tooltip ? (
    <div className="tooltip">
      <span className="tooltip-text bg-white shadow-md p-2 z-50 -mt-6 text-sm text-gray-500">{tooltip}</span>
      {children}
    </div>
  ) : (
    <>{children}</>
  );
}
