import { useMemo, useState } from "react";
import GoogleMapModal from "./modals/GoogleMapModal";
import PlacePickerModal from "@modules/bookings/transfers/components/PlacePickerModal";
import AppButton from "@components/AppButton";

export default function AppLocationPicker({ value, onChange, address, coordinates, className }) {
  const [locationModal, setLocationModal] = useState({ enabled: false, initial: undefined });
  const [placeModal, setPlaceModal] = useState({ enabled: false });

  const _handleChange = (address, coordinates) => onChange({ address, location: { coordinates } });

  // Render Location Modal
  const renderLocationModal = useMemo(
    () => (
      <GoogleMapModal
        onClose={() => setLocationModal({ enabled: false })}
        initial={locationModal.initial}
        onChange={({ address, coordinates }) => _handleChange(address, coordinates)}
      />
    ),
    [locationModal.enabled]
  );

  // Render Place Picker Modal
  const renderPlaceModal = useMemo(
    () => (
      <PlacePickerModal
        onClose={() => setPlaceModal({ enabled: false })}
        onChange={({ address, coordinates }) => _handleChange(address, coordinates)}
      />
    ),
    [placeModal.enabled]
  );

  return (
    <div className={`flex items-center space-x-1 ${className}`}>
      <input type="text" className="form bg-gray-50" disabled value={value?.address} placeholder="L.ADDRESS" />
      <AppButton icon="fa-bookmark" onClick={() => setPlaceModal({ enabled: true })} />
      <AppButton icon="fa-map-marker-alt" onClick={() => setLocationModal({ enabled: true, initial: coordinates })} />
      {placeModal.enabled && renderPlaceModal}
      {locationModal.enabled && renderLocationModal}
    </div>
  );
}
