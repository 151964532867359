export default function MediaWrapper({ mime = "image/*", url, classname, ...props }) {
  const type = mime?.split("/")[0];
  return (
    <>
      {type === "image" && <img {...props} src={url} loading="lazy" />}
      {type === "video" && <video {...props} src={url} muted controls autoPlay={false} />}
      {type === "audio" && <audio src={url} controls />}
    </>
  );
}
