import { useContext } from "react";
import { AppContext } from "@context/AppContext";
export default function useLocale() {
  const {
    CM: { profile: company },
    PM: { profile },
  } = useContext(AppContext);

  const locale = profile.default?.locale ?? company.default?.locale ?? "en";

  const translate = (value) => {
    if (!value) return "N/A";
    else if (typeof value === "string") return value;
    else if (typeof value === "object" && !Array.isArray(value)) return value[locale] || "N/A";
    else return "N/A";
  };

  return {
    locale,
    t: translate,
    T: ({ children }) => <>{translate(children)}</>,
  };
}
