const COUNTRIES = [
  {
    name: "Afghanistan",
    flag: "🇦🇫",
    ISO: "AF",
    code: "93",
  },
  {
    name: "Åland Islands",
    flag: "🇦🇽",
    ISO: "AX",
    code: "358",
  },
  {
    name: "Albania",
    flag: "🇦🇱",
    ISO: "AL",
    code: "355",
  },
  {
    name: "Algeria",
    flag: "🇩🇿",
    ISO: "DZ",
    code: "213",
  },
  {
    name: "American Samoa",
    flag: "🇦🇸",
    ISO: "AS",
    code: "1684",
  },
  {
    name: "Andorra",
    flag: "🇦🇩",
    ISO: "AD",
    code: "376",
  },
  {
    name: "Angola",
    flag: "🇦🇴",
    ISO: "AO",
    code: "244",
  },
  {
    name: "Anguilla",
    flag: "🇦🇮",
    ISO: "AI",
    code: "1264",
  },
  {
    name: "Antarctica",
    flag: "🇦🇶",
    ISO: "AQ",
    code: "672",
  },
  {
    name: "Antigua and Barbuda",
    flag: "🇦🇬",
    ISO: "AG",
    code: "1268",
  },
  {
    name: "Argentina",
    flag: "🇦🇷",
    ISO: "AR",
    code: "54",
  },
  {
    name: "Armenia",
    flag: "🇦🇲",
    ISO: "AM",
    code: "374",
  },
  {
    name: "Aruba",
    flag: "🇦🇼",
    ISO: "AW",
    code: "297",
  },
  {
    name: "Australia",
    flag: "🇦🇺",
    ISO: "AU",
    code: "61",
  },
  {
    name: "Austria",
    flag: "🇦🇹",
    ISO: "AT",
    code: "43",
  },
  {
    name: "Azerbaijan",
    flag: "🇦🇿",
    ISO: "AZ",
    code: "994",
  },
  {
    name: "Bahamas",
    flag: "🇧🇸",
    ISO: "BS",
    code: "1242",
  },
  {
    name: "Bahrain",
    flag: "🇧🇭",
    ISO: "BH",
    code: "973",
  },
  {
    name: "Bangladesh",
    flag: "🇧🇩",
    ISO: "BD",
    code: "880",
  },
  {
    name: "Barbados",
    flag: "🇧🇧",
    ISO: "BB",
    code: "1246",
  },
  {
    name: "Belarus",
    flag: "🇧🇾",
    ISO: "BY",
    code: "375",
  },
  {
    name: "Belgium",
    flag: "🇧🇪",
    ISO: "BE",
    code: "32",
  },
  {
    name: "Belize",
    flag: "🇧🇿",
    ISO: "BZ",
    code: "501",
  },
  {
    name: "Benin",
    flag: "🇧🇯",
    ISO: "BJ",
    code: "229",
  },
  {
    name: "Bermuda",
    flag: "🇧🇲",
    ISO: "BM",
    code: "1441",
  },
  {
    name: "Bhutan",
    flag: "🇧🇹",
    ISO: "BT",
    code: "975",
  },
  {
    name: "Bolivia, Plurinational State of bolivia",
    flag: "🇧🇴",
    ISO: "BO",
    code: "591",
  },
  {
    name: "Bosnia and Herzegovina",
    flag: "🇧🇦",
    ISO: "BA",
    code: "387",
  },
  {
    name: "Botswana",
    flag: "🇧🇼",
    ISO: "BW",
    code: "267",
  },
  {
    name: "Bouvet Island",
    flag: "🇧🇻",
    ISO: "BV",
    code: "47",
  },
  {
    name: "Brazil",
    flag: "🇧🇷",
    ISO: "BR",
    code: "55",
  },
  {
    name: "British Indian Ocean Territory",
    flag: "🇮🇴",
    ISO: "IO",
    code: "246",
  },
  {
    name: "Brunei Darussalam",
    flag: "🇧🇳",
    ISO: "BN",
    code: "673",
  },
  {
    name: "Bulgaria",
    flag: "🇧🇬",
    ISO: "BG",
    code: "359",
  },
  {
    name: "Burkina Faso",
    flag: "🇧🇫",
    ISO: "BF",
    code: "226",
  },
  {
    name: "Burundi",
    flag: "🇧🇮",
    ISO: "BI",
    code: "257",
  },
  {
    name: "Cambodia",
    flag: "🇰🇭",
    ISO: "KH",
    code: "855",
  },
  {
    name: "Cameroon",
    flag: "🇨🇲",
    ISO: "CM",
    code: "237",
  },
  {
    name: "Canada",
    flag: "🇨🇦",
    ISO: "CA",
    code: "1",
  },
  {
    name: "Cape Verde",
    flag: "🇨🇻",
    ISO: "CV",
    code: "238",
  },
  {
    name: "Cayman Islands",
    flag: "🇰🇾",
    ISO: "KY",
    code: "345",
  },
  {
    name: "Central African Republic",
    flag: "🇨🇫",
    ISO: "CF",
    code: "236",
  },
  {
    name: "Chad",
    flag: "🇹🇩",
    ISO: "TD",
    code: "235",
  },
  {
    name: "Chile",
    flag: "🇨🇱",
    ISO: "CL",
    code: "56",
  },
  {
    name: "China",
    flag: "🇨🇳",
    ISO: "CN",
    code: "86",
  },
  {
    name: "Christmas Island",
    flag: "🇨🇽",
    ISO: "CX",
    code: "61",
  },
  {
    name: "Cocos (Keeling) Islands",
    flag: "🇨🇨",
    ISO: "CC",
    code: "61",
  },
  {
    name: "Colombia",
    flag: "🇨🇴",
    ISO: "CO",
    code: "57",
  },
  {
    name: "Comoros",
    flag: "🇰🇲",
    ISO: "KM",
    code: "269",
  },
  {
    name: "Congo",
    flag: "🇨🇬",
    ISO: "CG",
    code: "242",
  },
  {
    name: "Congo, The Democratic Republic of the Congo",
    flag: "🇨🇩",
    ISO: "CD",
    code: "243",
  },
  {
    name: "Cook Islands",
    flag: "🇨🇰",
    ISO: "CK",
    code: "682",
  },
  {
    name: "Costa Rica",
    flag: "🇨🇷",
    ISO: "CR",
    code: "506",
  },
  {
    name: "Cote d'Ivoire",
    flag: "🇨🇮",
    ISO: "CI",
    code: "225",
  },
  {
    name: "Croatia",
    flag: "🇭🇷",
    ISO: "HR",
    code: "385",
  },
  {
    name: "Cuba",
    flag: "🇨🇺",
    ISO: "CU",
    code: "53",
  },
  {
    name: "Cyprus",
    flag: "🇨🇾",
    ISO: "CY",
    code: "357",
  },
  {
    name: "Czech Republic",
    flag: "🇨🇿",
    ISO: "CZ",
    code: "420",
  },
  {
    name: "Denmark",
    flag: "🇩🇰",
    ISO: "DK",
    code: "45",
  },
  {
    name: "Djibouti",
    flag: "🇩🇯",
    ISO: "DJ",
    code: "253",
  },
  {
    name: "Dominica",
    flag: "🇩🇲",
    ISO: "DM",
    code: "1767",
  },
  {
    name: "Dominican Republic",
    flag: "🇩🇴",
    ISO: "DO",
    code: "1849",
  },
  {
    name: "Ecuador",
    flag: "🇪🇨",
    ISO: "EC",
    code: "593",
  },
  {
    name: "Egypt",
    flag: "🇪🇬",
    ISO: "EG",
    code: "20",
  },
  {
    name: "El Salvador",
    flag: "🇸🇻",
    ISO: "SV",
    code: "503",
  },
  {
    name: "Equatorial Guinea",
    flag: "🇬🇶",
    ISO: "GQ",
    code: "240",
  },
  {
    name: "Eritrea",
    flag: "🇪🇷",
    ISO: "ER",
    code: "291",
  },
  {
    name: "Estonia",
    flag: "🇪🇪",
    ISO: "EE",
    code: "372",
  },
  {
    name: "Ethiopia",
    flag: "🇪🇹",
    ISO: "ET",
    code: "251",
  },
  {
    name: "Falkland Islands (Malvinas)",
    flag: "🇫🇰",
    ISO: "FK",
    code: "500",
  },
  {
    name: "Faroe Islands",
    flag: "🇫🇴",
    ISO: "FO",
    code: "298",
  },
  {
    name: "Fiji",
    flag: "🇫🇯",
    ISO: "FJ",
    code: "679",
  },
  {
    name: "Finland",
    flag: "🇫🇮",
    ISO: "FI",
    code: "358",
  },
  {
    name: "France",
    flag: "🇫🇷",
    ISO: "FR",
    code: "33",
  },
  {
    name: "French Guiana",
    flag: "🇬🇫",
    ISO: "GF",
    code: "594",
  },
  {
    name: "French Polynesia",
    flag: "🇵🇫",
    ISO: "PF",
    code: "689",
  },
  {
    name: "French Southern Territories",
    flag: "🇹🇫",
    ISO: "TF",
    code: "262",
  },
  {
    name: "Gabon",
    flag: "🇬🇦",
    ISO: "GA",
    code: "241",
  },
  {
    name: "Gambia",
    flag: "🇬🇲",
    ISO: "GM",
    code: "220",
  },
  {
    name: "Georgia",
    flag: "🇬🇪",
    ISO: "GE",
    code: "995",
  },
  {
    name: "Germany",
    flag: "🇩🇪",
    ISO: "DE",
    code: "49",
  },
  {
    name: "Ghana",
    flag: "🇬🇭",
    ISO: "GH",
    code: "233",
  },
  {
    name: "Gibraltar",
    flag: "🇬🇮",
    ISO: "GI",
    code: "350",
  },
  {
    name: "Greece",
    flag: "🇬🇷",
    ISO: "GR",
    code: "30",
  },
  {
    name: "Greenland",
    flag: "🇬🇱",
    ISO: "GL",
    code: "299",
  },
  {
    name: "Grenada",
    flag: "🇬🇩",
    ISO: "GD",
    code: "1473",
  },
  {
    name: "Guadeloupe",
    flag: "🇬🇵",
    ISO: "GP",
    code: "590",
  },
  {
    name: "Guam",
    flag: "🇬🇺",
    ISO: "GU",
    code: "1671",
  },
  {
    name: "Guatemala",
    flag: "🇬🇹",
    ISO: "GT",
    code: "502",
  },
  {
    name: "Guernsey",
    flag: "🇬🇬",
    ISO: "GG",
    code: "44",
  },
  {
    name: "Guinea",
    flag: "🇬🇳",
    ISO: "GN",
    code: "224",
  },
  {
    name: "Guinea-Bissau",
    flag: "🇬🇼",
    ISO: "GW",
    code: "245",
  },
  {
    name: "Guyana",
    flag: "🇬🇾",
    ISO: "GY",
    code: "592",
  },
  {
    name: "Haiti",
    flag: "🇭🇹",
    ISO: "HT",
    code: "509",
  },
  {
    name: "Heard Island and Mcdonald Islands",
    flag: "🇭🇲",
    ISO: "HM",
    code: "672",
  },
  {
    name: "Holy See (Vatican City State)",
    flag: "🇻🇦",
    ISO: "VA",
    code: "379",
  },
  {
    name: "Honduras",
    flag: "🇭🇳",
    ISO: "HN",
    code: "504",
  },
  {
    name: "Hong Kong",
    flag: "🇭🇰",
    ISO: "HK",
    code: "852",
  },
  {
    name: "Hungary",
    flag: "🇭🇺",
    ISO: "HU",
    code: "36",
  },
  {
    name: "Iceland",
    flag: "🇮🇸",
    ISO: "IS",
    code: "354",
  },
  {
    name: "India",
    flag: "🇮🇳",
    ISO: "IN",
    code: "91",
  },
  {
    name: "Indonesia",
    flag: "🇮🇩",
    ISO: "ID",
    code: "62",
  },
  {
    name: "Iran, Islamic Republic of Persian Gulf",
    flag: "🇮🇷",
    ISO: "IR",
    code: "98",
  },
  {
    name: "Iraq",
    flag: "🇮🇶",
    ISO: "IQ",
    code: "964",
  },
  {
    name: "Ireland",
    flag: "🇮🇪",
    ISO: "IE",
    code: "353",
  },
  {
    name: "Isle of Man",
    flag: "🇮🇲",
    ISO: "IM",
    code: "44",
  },
  {
    name: "Israel",
    flag: "🇮🇱",
    ISO: "IL",
    code: "972",
  },
  {
    name: "Italy",
    flag: "🇮🇹",
    ISO: "IT",
    code: "39",
  },
  {
    name: "Jamaica",
    flag: "🇯🇲",
    ISO: "JM",
    code: "1876",
  },
  {
    name: "Japan",
    flag: "🇯🇵",
    ISO: "JP",
    code: "81",
  },
  {
    name: "Jersey",
    flag: "🇯🇪",
    ISO: "JE",
    code: "44",
  },
  {
    name: "Jordan",
    flag: "🇯🇴",
    ISO: "JO",
    code: "962",
  },
  {
    name: "Kazakhstan",
    flag: "🇰🇿",
    ISO: "KZ",
    code: "7",
  },
  {
    name: "Kenya",
    flag: "🇰🇪",
    ISO: "KE",
    code: "254",
  },
  {
    name: "Kiribati",
    flag: "🇰🇮",
    ISO: "KI",
    code: "686",
  },
  {
    name: "Korea, Democratic People's Republic of Korea",
    flag: "🇰🇵",
    ISO: "KP",
    code: "850",
  },
  {
    name: "Korea, Republic of South Korea",
    flag: "🇰🇷",
    ISO: "KR",
    code: "82",
  },
  {
    name: "Kosovo",
    flag: "🇽🇰",
    ISO: "XK",
    code: "383",
  },
  {
    name: "Kuwait",
    flag: "🇰🇼",
    ISO: "KW",
    code: "965",
  },
  {
    name: "Kyrgyzstan",
    flag: "🇰🇬",
    ISO: "KG",
    code: "996",
  },
  {
    name: "Laos",
    flag: "🇱🇦",
    ISO: "LA",
    code: "856",
  },
  {
    name: "Latvia",
    flag: "🇱🇻",
    ISO: "LV",
    code: "371",
  },
  {
    name: "Lebanon",
    flag: "🇱🇧",
    ISO: "LB",
    code: "961",
  },
  {
    name: "Lesotho",
    flag: "🇱🇸",
    ISO: "LS",
    code: "266",
  },
  {
    name: "Liberia",
    flag: "🇱🇷",
    ISO: "LR",
    code: "231",
  },
  {
    name: "Libyan Arab Jamahiriya",
    flag: "🇱🇾",
    ISO: "LY",
    code: "218",
  },
  {
    name: "Liechtenstein",
    flag: "🇱🇮",
    ISO: "LI",
    code: "423",
  },
  {
    name: "Lithuania",
    flag: "🇱🇹",
    ISO: "LT",
    code: "370",
  },
  {
    name: "Luxembourg",
    flag: "🇱🇺",
    ISO: "LU",
    code: "352",
  },
  {
    name: "Macao",
    flag: "🇲🇴",
    ISO: "MO",
    code: "853",
  },
  {
    name: "Macedonia",
    flag: "🇲🇰",
    ISO: "MK",
    code: "389",
  },
  {
    name: "Madagascar",
    flag: "🇲🇬",
    ISO: "MG",
    code: "261",
  },
  {
    name: "Malawi",
    flag: "🇲🇼",
    ISO: "MW",
    code: "265",
  },
  {
    name: "Malaysia",
    flag: "🇲🇾",
    ISO: "MY",
    code: "60",
  },
  {
    name: "Maldives",
    flag: "🇲🇻",
    ISO: "MV",
    code: "960",
  },
  {
    name: "Mali",
    flag: "🇲🇱",
    ISO: "ML",
    code: "223",
  },
  {
    name: "Malta",
    flag: "🇲🇹",
    ISO: "MT",
    code: "356",
  },
  {
    name: "Marshall Islands",
    flag: "🇲🇭",
    ISO: "MH",
    code: "692",
  },
  {
    name: "Martinique",
    flag: "🇲🇶",
    ISO: "MQ",
    code: "596",
  },
  {
    name: "Mauritania",
    flag: "🇲🇷",
    ISO: "MR",
    code: "222",
  },
  {
    name: "Mauritius",
    flag: "🇲🇺",
    ISO: "MU",
    code: "230",
  },
  {
    name: "Mayotte",
    flag: "🇾🇹",
    ISO: "YT",
    code: "262",
  },
  {
    name: "Mexico",
    flag: "🇲🇽",
    ISO: "MX",
    code: "52",
  },
  {
    name: "Micronesia, Federated States of Micronesia",
    flag: "🇫🇲",
    ISO: "FM",
    code: "691",
  },
  {
    name: "Moldova",
    flag: "🇲🇩",
    ISO: "MD",
    code: "373",
  },
  {
    name: "Monaco",
    flag: "🇲🇨",
    ISO: "MC",
    code: "377",
  },
  {
    name: "Mongolia",
    flag: "🇲🇳",
    ISO: "MN",
    code: "976",
  },
  {
    name: "Montenegro",
    flag: "🇲🇪",
    ISO: "ME",
    code: "382",
  },
  {
    name: "Montserrat",
    flag: "🇲🇸",
    ISO: "MS",
    code: "1664",
  },
  {
    name: "Morocco",
    flag: "🇲🇦",
    ISO: "MA",
    code: "212",
  },
  {
    name: "Mozambique",
    flag: "🇲🇿",
    ISO: "MZ",
    code: "258",
  },
  {
    name: "Myanmar",
    flag: "🇲🇲",
    ISO: "MM",
    code: "95",
  },
  {
    name: "Namibia",
    flag: "🇳🇦",
    ISO: "NA",
    code: "264",
  },
  {
    name: "Nauru",
    flag: "🇳🇷",
    ISO: "NR",
    code: "674",
  },
  {
    name: "Nepal",
    flag: "🇳🇵",
    ISO: "NP",
    code: "977",
  },
  {
    name: "Netherlands",
    flag: "🇳🇱",
    ISO: "NL",
    code: "31",
  },
  {
    name: "Netherlands Antilles",
    flag: "",
    ISO: "AN",
    code: "599",
  },
  {
    name: "New Caledonia",
    flag: "🇳🇨",
    ISO: "NC",
    code: "687",
  },
  {
    name: "New Zealand",
    flag: "🇳🇿",
    ISO: "NZ",
    code: "64",
  },
  {
    name: "Nicaragua",
    flag: "🇳🇮",
    ISO: "NI",
    code: "505",
  },
  {
    name: "Niger",
    flag: "🇳🇪",
    ISO: "NE",
    code: "227",
  },
  {
    name: "Nigeria",
    flag: "🇳🇬",
    ISO: "NG",
    code: "234",
  },
  {
    name: "Niue",
    flag: "🇳🇺",
    ISO: "NU",
    code: "683",
  },
  {
    name: "Norfolk Island",
    flag: "🇳🇫",
    ISO: "NF",
    code: "672",
  },
  {
    name: "Northern Mariana Islands",
    flag: "🇲🇵",
    ISO: "MP",
    code: "1670",
  },
  {
    name: "Norway",
    flag: "🇳🇴",
    ISO: "NO",
    code: "47",
  },
  {
    name: "Oman",
    flag: "🇴🇲",
    ISO: "OM",
    code: "968",
  },
  {
    name: "Pakistan",
    flag: "🇵🇰",
    ISO: "PK",
    code: "92",
  },
  {
    name: "Palau",
    flag: "🇵🇼",
    ISO: "PW",
    code: "680",
  },
  {
    name: "Palestinian Territory, Occupied",
    flag: "🇵🇸",
    ISO: "PS",
    code: "970",
  },
  {
    name: "Panama",
    flag: "🇵🇦",
    ISO: "PA",
    code: "507",
  },
  {
    name: "Papua New Guinea",
    flag: "🇵🇬",
    ISO: "PG",
    code: "675",
  },
  {
    name: "Paraguay",
    flag: "🇵🇾",
    ISO: "PY",
    code: "595",
  },
  {
    name: "Peru",
    flag: "🇵🇪",
    ISO: "PE",
    code: "51",
  },
  {
    name: "Philippines",
    flag: "🇵🇭",
    ISO: "PH",
    code: "63",
  },
  {
    name: "Pitcairn",
    flag: "🇵🇳",
    ISO: "PN",
    code: "64",
  },
  {
    name: "Poland",
    flag: "🇵🇱",
    ISO: "PL",
    code: "48",
  },
  {
    name: "Portugal",
    flag: "🇵🇹",
    ISO: "PT",
    code: "351",
  },
  {
    name: "Puerto Rico",
    flag: "🇵🇷",
    ISO: "PR",
    code: "1939",
  },
  {
    name: "Qatar",
    flag: "🇶🇦",
    ISO: "QA",
    code: "974",
  },
  {
    name: "Romania",
    flag: "🇷🇴",
    ISO: "RO",
    code: "40",
  },
  {
    name: "Russia",
    flag: "🇷🇺",
    ISO: "RU",
    code: "7",
  },
  {
    name: "Rwanda",
    flag: "🇷🇼",
    ISO: "RW",
    code: "250",
  },
  {
    name: "Reunion",
    flag: "🇷🇪",
    ISO: "RE",
    code: "262",
  },
  {
    name: "Saint Barthelemy",
    flag: "🇧🇱",
    ISO: "BL",
    code: "590",
  },
  {
    name: "Saint Helena, Ascension and Tristan Da Cunha",
    flag: "🇸🇭",
    ISO: "SH",
    code: "290",
  },
  {
    name: "Saint Kitts and Nevis",
    flag: "🇰🇳",
    ISO: "KN",
    code: "1869",
  },
  {
    name: "Saint Lucia",
    flag: "🇱🇨",
    ISO: "LC",
    code: "1758",
  },
  {
    name: "Saint Martin",
    flag: "🇲🇫",
    ISO: "MF",
    code: "590",
  },
  {
    name: "Saint Pierre and Miquelon",
    flag: "🇵🇲",
    ISO: "PM",
    code: "508",
  },
  {
    name: "Saint Vincent and the Grenadines",
    flag: "🇻🇨",
    ISO: "VC",
    code: "1784",
  },
  {
    name: "Samoa",
    flag: "🇼🇸",
    ISO: "WS",
    code: "685",
  },
  {
    name: "San Marino",
    flag: "🇸🇲",
    ISO: "SM",
    code: "378",
  },
  {
    name: "Sao Tome and Principe",
    flag: "🇸🇹",
    ISO: "ST",
    code: "239",
  },
  {
    name: "Saudi Arabia",
    flag: "🇸🇦",
    ISO: "SA",
    code: "966",
  },
  {
    name: "Senegal",
    flag: "🇸🇳",
    ISO: "SN",
    code: "221",
  },
  {
    name: "Serbia",
    flag: "🇷🇸",
    ISO: "RS",
    code: "381",
  },
  {
    name: "Seychelles",
    flag: "🇸🇨",
    ISO: "SC",
    code: "248",
  },
  {
    name: "Sierra Leone",
    flag: "🇸🇱",
    ISO: "SL",
    code: "232",
  },
  {
    name: "Singapore",
    flag: "🇸🇬",
    ISO: "SG",
    code: "65",
  },
  {
    name: "Slovakia",
    flag: "🇸🇰",
    ISO: "SK",
    code: "421",
  },
  {
    name: "Slovenia",
    flag: "🇸🇮",
    ISO: "SI",
    code: "386",
  },
  {
    name: "Solomon Islands",
    flag: "🇸🇧",
    ISO: "SB",
    code: "677",
  },
  {
    name: "Somalia",
    flag: "🇸🇴",
    ISO: "SO",
    code: "252",
  },
  {
    name: "South Africa",
    flag: "🇿🇦",
    ISO: "ZA",
    code: "27",
  },
  {
    name: "South Sudan",
    flag: "🇸🇸",
    ISO: "SS",
    code: "211",
  },
  {
    name: "South Georgia and the South Sandwich Islands",
    flag: "🇬🇸",
    ISO: "GS",
    code: "500",
  },
  {
    name: "Spain",
    flag: "🇪🇸",
    ISO: "ES",
    code: "34",
  },
  {
    name: "Sri Lanka",
    flag: "🇱🇰",
    ISO: "LK",
    code: "94",
  },
  {
    name: "Sudan",
    flag: "🇸🇩",
    ISO: "SD",
    code: "249",
  },
  {
    name: "Suriname",
    flag: "🇸🇷",
    ISO: "SR",
    code: "597",
  },
  {
    name: "Svalbard and Jan Mayen",
    flag: "🇸🇯",
    ISO: "SJ",
    code: "47",
  },
  {
    name: "Swaziland",
    flag: "🇸🇿",
    ISO: "SZ",
    code: "268",
  },
  {
    name: "Sweden",
    flag: "🇸🇪",
    ISO: "SE",
    code: "46",
  },
  {
    name: "Switzerland",
    flag: "🇨🇭",
    ISO: "CH",
    code: "41",
  },
  {
    name: "Syrian Arab Republic",
    flag: "🇸🇾",
    ISO: "SY",
    code: "963",
  },
  {
    name: "Taiwan",
    flag: "🇹🇼",
    ISO: "TW",
    code: "886",
  },
  {
    name: "Tajikistan",
    flag: "🇹🇯",
    ISO: "TJ",
    code: "992",
  },
  {
    name: "Tanzania, United Republic of Tanzania",
    flag: "🇹🇿",
    ISO: "TZ",
    code: "255",
  },
  {
    name: "Thailand",
    flag: "🇹🇭",
    ISO: "TH",
    code: "66",
  },
  {
    name: "Timor-Leste",
    flag: "🇹🇱",
    ISO: "TL",
    code: "670",
  },
  {
    name: "Togo",
    flag: "🇹🇬",
    ISO: "TG",
    code: "228",
  },
  {
    name: "Tokelau",
    flag: "🇹🇰",
    ISO: "TK",
    code: "690",
  },
  {
    name: "Tonga",
    flag: "🇹🇴",
    ISO: "TO",
    code: "676",
  },
  {
    name: "Trinidad and Tobago",
    flag: "🇹🇹",
    ISO: "TT",
    code: "1868",
  },
  {
    name: "Tunisia",
    flag: "🇹🇳",
    ISO: "TN",
    code: "216",
  },
  {
    name: "Turkey",
    flag: "🇹🇷",
    ISO: "TR",
    code: "90",
  },
  {
    name: "Turkmenistan",
    flag: "🇹🇲",
    ISO: "TM",
    code: "993",
  },
  {
    name: "Turks and Caicos Islands",
    flag: "🇹🇨",
    ISO: "TC",
    code: "1649",
  },
  {
    name: "Tuvalu",
    flag: "🇹🇻",
    ISO: "TV",
    code: "688",
  },
  {
    name: "Uganda",
    flag: "🇺🇬",
    ISO: "UG",
    code: "256",
  },
  {
    name: "Ukraine",
    flag: "🇺🇦",
    ISO: "UA",
    code: "380",
  },
  {
    name: "United Arab Emirates",
    flag: "🇦🇪",
    ISO: "AE",
    code: "971",
  },
  {
    name: "United Kingdom",
    flag: "🇬🇧",
    ISO: "GB",
    code: "44",
  },
  {
    name: "United States",
    flag: "🇺🇸",
    ISO: "US",
    code: "1",
  },
  {
    name: "Uruguay",
    flag: "🇺🇾",
    ISO: "UY",
    code: "598",
  },
  {
    name: "Uzbekistan",
    flag: "🇺🇿",
    ISO: "UZ",
    code: "998",
  },
  {
    name: "Vanuatu",
    flag: "🇻🇺",
    ISO: "VU",
    code: "678",
  },
  {
    name: "Venezuela, Bolivarian Republic of Venezuela",
    flag: "🇻🇪",
    ISO: "VE",
    code: "58",
  },
  {
    name: "Vietnam",
    flag: "🇻🇳",
    ISO: "VN",
    code: "84",
  },
  {
    name: "Virgin Islands, British",
    flag: "🇻🇬",
    ISO: "VG",
    code: "1284",
  },
  {
    name: "Virgin Islands, U.S.",
    flag: "🇻🇮",
    ISO: "VI",
    code: "1340",
  },
  {
    name: "Wallis and Futuna",
    flag: "🇼🇫",
    ISO: "WF",
    code: "681",
  },
  {
    name: "Yemen",
    flag: "🇾🇪",
    ISO: "YE",
    code: "967",
  },
  {
    name: "Zambia",
    flag: "🇿🇲",
    ISO: "ZM",
    code: "260",
  },
  {
    name: "Zimbabwe",
    flag: "🇿🇼",
    ISO: "ZW",
    code: "263",
  },
];

export default COUNTRIES;
