import AppIcon from "@components/AppIcon";
import { toast } from "react-toastify";
import "./toast.css";
const props = {
  position: "bottom-right",
  autoClose: 1000,
  hideProgressBar: true,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: false,
  progress: undefined,
};
const success = (text) =>
  // Success
  toast.success(text, {
    ...props,
    className: "toast-success",
    icon: <AppIcon code="fa-check-circle" className="text-white flex-shrink-0" />,
    closeButton: <AppIcon code="fa-times" className="text-white w-5 h-5 flex-shrink-0" />,
  });

// Warning
const warning = (text) =>
  toast.warning(text, {
    ...props,
    className: "toast-warning",
    icon: <AppIcon code="fa-info-circle" className="text-white flex-shrink-0" />,
    closeButton: <AppIcon code="fa-times" className="text-white w-5 h-5 flex-shrink-0" />,
  });

export default { success, warning };
