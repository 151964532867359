import clx from "classnames";
import { useIntl } from "react-intl";
const RowInput = ({ field, form, onChange, FormElement, name, label, icon, guide, placeholder, required, className, ...props }) => {
  const intl = useIntl();
  const _error = form?.errors?.[field?.name];
  return (
    <div className={clx("flex items-center justify-between flex-wrap", className)}>
      {label && (
        <div className="flex flex-col justify-center w-1/3 mx-0 md:mx-2">
          <label className="text-gray-500 font-medium">
            {intl.formatMessage({ id: label ?? "-" })}
            {required && <span className="text-red-500 ml-1">*</span>}
          </label>
          {_error && <span className="text-xs text-red-500 leading-none">{intl.formatMessage({ id: _error })}</span>}
        </div>
      )}
      <div className="flex-1">
        <FormElement
          name={name}
          placeholder={intl.formatMessage({
            id: Boolean(placeholder) ? placeholder : name ?? "-",
          })}
          value={field?.value}
          _error={_error}
          onChange={(value) => (onChange ? onChange(value) : form?.setFieldValue(field?.name, value))}
          {...props}
        />
      </div>
    </div>
  );
};

export default RowInput;
