import AppModal from "@components/AppModal";
import { AppInput } from "@components/form";
import { AppLayoutContext } from "@context/AppLayoutContext";
import axios from "axios";
import debounce from "lodash.debounce";
import { useCallback, useContext, useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import ENDPOINTS from "src/config/endpoints";
import useLocale from "src/hooks/useLocale";
import ElasticSearchItem from "./components/ElasticSearchItem";

export default function ElasticDialog() {
  const { elastic } = useContext(AppLayoutContext);
  const { enabled, close } = elastic;
  const [search, setSearch] = useState(undefined);

  // Get Leads Mutation
  const GLM = useQuery(["leads", search], async () => axios.get(ENDPOINTS.lead, { params: { search } }), { keepPreviousData: true });

  const handleSearch = (value) => setSearch(value);

  const { t } = useLocale();
  const navigate = useNavigate();

  const handleClick = useCallback((_id) => {
    close();
    return navigate(`/leads/${_id}/profile/overview`);
  });

  useEffect(() => {
    if (!enabled) setSearch(undefined);
  }, [enabled]);

  return (
    <AppModal
      show={enabled}
      onHide={close}
      header="T.ELASTIC_SEARCH"
      bodyClassName="p-0"
      body={
        <div className="flex flex-col">
          <div className="p-2 border-b bg-gray-50">
            <AppInput autoFocus onChange={debounce(handleSearch, 300)} />
          </div>
          {GLM.data?.leads?.length > 0 && (
            <div className="flex flex-col p-2 space-y-2 overflow-y-auto max-h-96">
              {GLM.data?.leads?.map((lead) => (
                <ElasticSearchItem key={lead?._id} lead={lead} onClick={handleClick} />
              ))}
            </div>
          )}
        </div>
      }
    />
  );
}
