import { Suspense, lazy } from "react";

// Import Lucide React icons
const CodeSquare = lazy(() => import("lucide-react/dist/esm/icons/code-square"));
const Globe = lazy(() => import("lucide-react/dist/esm/icons/globe"));
const HelpCircle = lazy(() => import("lucide-react/dist/esm/icons/help-circle"));
const Delete = lazy(() => import("lucide-react/dist/esm/icons/delete"));
const AlertCircle = lazy(() => import("lucide-react/dist/esm/icons/alert-circle"));
const LockIcon = lazy(() => import("lucide-react/dist/esm/icons/lock"));
const AlertTriangle = lazy(() => import("lucide-react/dist/esm/icons/alert-triangle"));
const Eye = lazy(() => import("lucide-react/dist/esm/icons/eye"));
const MessageSquare = lazy(() => import("lucide-react/dist/esm/icons/message-square"));
const BarChart = lazy(() => import("lucide-react/dist/esm/icons/bar-chart"));
const Users = lazy(() => import("lucide-react/dist/esm/icons/users"));
const ChevronDown = lazy(() => import("lucide-react/dist/esm/icons/chevron-down"));
const ArrowLeft = lazy(() => import("lucide-react/dist/esm/icons/arrow-left"));
const ArrowRight = lazy(() => import("lucide-react/dist/esm/icons/arrow-right"));
const ArrowLeftRight = lazy(() => import("lucide-react/dist/esm/icons/arrow-left-right"));
const Menu = lazy(() => import("lucide-react/dist/esm/icons/menu"));
const BellOff = lazy(() => import("lucide-react/dist/esm/icons/bell-off"));
const Bookmark = lazy(() => import("lucide-react/dist/esm/icons/bookmark"));
const BookOpen = lazy(() => import("lucide-react/dist/esm/icons/book-open"));
const Calendar = lazy(() => import("lucide-react/dist/esm/icons/calendar"));
const CheckCircle = lazy(() => import("lucide-react/dist/esm/icons/check-circle"));
const Check = lazy(() => import("lucide-react/dist/esm/icons/check"));
const ChevronLeft = lazy(() => import("lucide-react/dist/esm/icons/chevron-left"));
const ChevronRight = lazy(() => import("lucide-react/dist/esm/icons/chevron-right"));
const ChevronUp = lazy(() => import("lucide-react/dist/esm/icons/chevron-up"));
const Coins = lazy(() => import("lucide-react/dist/esm/icons/coins"));
const Clipboard = lazy(() => import("lucide-react/dist/esm/icons/clipboard"));
const Clock = lazy(() => import("lucide-react/dist/esm/icons/clock"));
const Settings = lazy(() => import("lucide-react/dist/esm/icons/settings"));
const Info = lazy(() => import("lucide-react/dist/esm/icons/info"));
const Home = lazy(() => import("lucide-react/dist/esm/icons/home"));
const Download = lazy(() => import("lucide-react/dist/esm/icons/download"));
const RefreshCw = lazy(() => import("lucide-react/dist/esm/icons/refresh-cw"));
const MoveVertical = lazy(() => import("lucide-react/dist/esm/icons/move-vertical"));
const BellRing = lazy(() => import("lucide-react/dist/esm/icons/bell-ring"));
const Zap = lazy(() => import("lucide-react/dist/esm/icons/zap"));
const ChevronsUpDown = lazy(() => import("lucide-react/dist/esm/icons/chevrons-up-down"));
const CloudDownload = lazy(() => import("lucide-react/dist/esm/icons/cloud-download"));
const CloudUpload = lazy(() => import("lucide-react/dist/esm/icons/cloud-upload"));
const MousePointerClick = lazy(() => import("lucide-react/dist/esm/icons/mouse-pointer-click"));
const MoreVertical = lazy(() => import("lucide-react/dist/esm/icons/more-vertical"));
const SmilePlus = lazy(() => import("lucide-react/dist/esm/icons/smile-plus"));
const Filter = lazy(() => import("lucide-react/dist/esm/icons/filter"));
const Map = lazy(() => import("lucide-react/dist/esm/icons/map"));
const MapPin = lazy(() => import("lucide-react/dist/esm/icons/map-pin"));
const Mic = lazy(() => import("lucide-react/dist/esm/icons/mic"));
const Minus = lazy(() => import("lucide-react/dist/esm/icons/minus"));
const Ban = lazy(() => import("lucide-react/dist/esm/icons/ban"));
const Send = lazy(() => import("lucide-react/dist/esm/icons/send"));
const Paperclip = lazy(() => import("lucide-react/dist/esm/icons/paperclip"));
const Pencil = lazy(() => import("lucide-react/dist/esm/icons/pencil"));
const Phone = lazy(() => import("lucide-react/dist/esm/icons/phone"));
const PlusCircle = lazy(() => import("lucide-react/dist/esm/icons/plus-circle"));
const Plus = lazy(() => import("lucide-react/dist/esm/icons/plus"));
const Share2 = lazy(() => import("lucide-react/dist/esm/icons/share-2"));
const Trash2 = lazy(() => import("lucide-react/dist/esm/icons/trash-2"));
const X = lazy(() => import("lucide-react/dist/esm/icons/x"));
const Hash = lazy(() => import("lucide-react/dist/esm/icons/hash"));
const Truck = lazy(() => import("lucide-react/dist/esm/icons/truck"));
const LayoutGrid = lazy(() => import("lucide-react/dist/esm/icons/layout-grid"));
const ShieldCheck = lazy(() => import("lucide-react/dist/esm/icons/shield-check"));
const UserCircle = lazy(() => import("lucide-react/dist/esm/icons/user-circle"));
const Pause = lazy(() => import("lucide-react/dist/esm/icons/pause"));
const LogOut = lazy(() => import("lucide-react/dist/esm/icons/log-out"));
const FileText = lazy(() => import("lucide-react/dist/esm/icons/file-text"));
const SearchIcon = lazy(() => import("lucide-react/dist/esm/icons/search"));
const PhoneOff = lazy(() => import("lucide-react/dist/esm/icons/phone-off"));
const Tag = lazy(() => import("lucide-react/dist/esm/icons/tag"));
const Newspaper = lazy(() => import("lucide-react/dist/esm/icons/newspaper"));
const ListOrdered = lazy(() => import("lucide-react/dist/esm/icons/list-ordered"));
const Bot = lazy(() => import("lucide-react/dist/esm/icons/bot"));

// Unicode Characters
const CircleIcon = "●";

const _FAHERO = {
  "fa-address-book": Bookmark,
  "fa-tag": Tag,
  "fa-queue-list": ListOrdered,
  "fa-newspaper": Newspaper,
  "fa-arrow-left": ArrowLeft,
  "fa-arrow-right": ArrowRight,
  "fa-arrows-alt": ArrowLeftRight,
  "fa-bars": Menu,
  "fa-bell-slash": BellOff,
  "fa-bell": BellRing,
  "fa-book": BookOpen,
  "fa-bookmark": Bookmark,
  "fa-calendar": Calendar,
  "fa-caret-down": ChevronDown,
  "fa-caret-up": ChevronUp,
  "fa-caret-right": ChevronRight,
  "fa-check": Check,
  "fa-chart": BarChart,
  "fa-check-circle": CheckCircle,
  "fa-chevron-down": ChevronDown,
  "fa-chevron-left": ChevronLeft,
  "fa-chevron-right": ChevronRight,
  "fa-chevron-up": ChevronUp,
  "fa-circle": CircleIcon,
  "fa-clock": Clock,
  "fa-cog": Settings,
  "fa-coins": Coins,
  "fa-compass": Filter,
  "fa-copy": Clipboard,
  "fa-drag": MoveVertical,
  "fa-dashboard": Home,
  "fa-download": Download,
  "fa-ellipsis-v": MoreVertical,
  "fa-exclamation-circle": AlertCircle,
  "fa-exclamation-triangle": AlertTriangle,
  "fa-eye": Eye,
  "fa-export": CloudDownload,
  "fa-filter": Filter,
  "fa-info-circle": Info,
  "fa-map": Map,
  "fa-map-marker-alt": MapPin,
  "fa-pen": Pencil,
  "fa-phone": Phone,
  "fa-plus": Plus,
  "fa-plus-circle": PlusCircle,
  "fa-share": Share2,
  "fa-sms": MessageSquare,
  "fa-sync": RefreshCw,
  "fa-spinner": RefreshCw,
  "fa-times": X,
  "fa-x": X,
  "fa-trash": Trash2,
  "fa-upload": CloudUpload,
  "fa-users": Users,
  "fa-minus": Minus,
  "fa-smile": SmilePlus,
  "fa-microphone": Mic,
  "fa-paper-plane": Send,
  "fa-paperclip": Paperclip,
  "fa-chevron-up-down": ChevronsUpDown,
  "fa-bolt": Zap,
  "fa-hashtag": Hash,
  "fa-no-symbol": Ban,
  "fa-marketing": MousePointerClick,
  "fa-truck": Truck,
  "fa-window": LayoutGrid,
  "fa-shield-check": ShieldCheck,
  "fa-user-circle": UserCircle,
  "fa-pause": Pause,
  "fa-exit": LogOut,
  "fa-lock": LockIcon,
  "fa-document": FileText,
  "fa-search-circle": SearchIcon,
  "fa-backspace": Delete,
  "fa-bracket": CodeSquare,
  "fa-question-circle": HelpCircle,
  "fa-phone-x": PhoneOff,
  "fa-globe": Globe,
  "fa-bot": Bot,
};

export default function AppIcon({ code = "", className = "", ...props }) {
  if (!code || !_FAHERO[code]) return <></>;
  const Item = typeof _FAHERO[code] === "object" ? _FAHERO[code] : ({ ...props }) => <span {...props}> {_FAHERO[code]} </span>;
  return (
    <Suspense fallback={<></>}>
      <Item className={className} {...props} />
    </Suspense>
  );
}
