import { APP_VERSION } from "@config";
import axios from "axios";
import { useEffect } from "react";
import { useMutation } from "react-query";
import ENDPOINTS from "src/config/endpoints";
import VersionAlertModal from "./components/VersionAlertModal";

export default function AppVersionCheck() {
  /** */

  // Get App Latest Version
  const GVM = useMutation(() => axios.get(`${ENDPOINTS.misc}/app/version/latest`));

  useEffect(() => {
    GVM.mutate(); // Initial Check
    const intervalId = setInterval(GVM.mutate, 30 * 1000); // Re-check Every 30 Seconds
    return () => clearInterval(intervalId);
  }, []);

  return <>{GVM.data?.number > Number(APP_VERSION ?? 0) && <VersionAlertModal version={GVM.data} />}</>;
}
