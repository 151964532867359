import moment from "moment-timezone";

const NOTIF_PAYLOAD = {
  // UUID Unique Identifier Set By Server
  UUID_1: {
    display: { name: "", thumbnail: "" }, // How Notification P
    code: "NEW.MESSAGE",
    updatedAt: "",
    payload: { leadID: "", conversationID: "" },
    count: 1,
    seen: false,
    beep: true,
  },
};

// Get All Notifications
const getAll = () => {
  try {
    if (!localStorage.getItem("notifications")) return [];
    const localData = JSON.parse(localStorage.getItem("notifications"));
    const notifications = Object.keys(localData).map((UUID) => ({ UUID, ...localData[UUID] }));
    // Sort By Data Updated
    notifications.sort((a, b) => (moment(a.updatedAt).isBefore(moment(b.updatedAt)) ? -1 : 1));
    return notifications;
  } catch (error) {
    return [];
  }
};

// Seen Single Notification
const seen = (UUID) => {
  const localData = localStorage.getItem("notifications") ? JSON.parse(localStorage.getItem("notifications")) : {};
  if (!localData[UUID]) return;
  localData[UUID] = { ...localData[UUID], seen: true, count: 0 };
  return localStorage.setItem("notifications", JSON.stringify(localData));
};

// Remove All Notifications
const removeAll = () => localStorage.removeItem("notifications");

// Add Notification
const add = (_payload = {}) => {
  const { UUID, code, updatedAt, display, beep = false, payload = {} } = _payload;
  if (!UUID) return;
  const localData = localStorage.getItem("notifications") ? JSON.parse(localStorage.getItem("notifications")) : {};
  if (localData[UUID]) localData[UUID] = { ...localData[UUID], beep, seen: false, count: (localData[UUID]?.count ?? 0) + 1, updatedAt }; // Update beep & seen
  else localData[UUID] = { display, code, payload, count: 1, seen: false, beep, updatedAt }; // seen is false on new notifications
  return localStorage.setItem("notifications", JSON.stringify(localData));
};

const notificationService = { getAll, removeAll, add, seen };
export default notificationService;
