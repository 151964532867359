import DashboardPage from "@modules/dashboard/DashboardPage";
import { lazy } from "react";
import { Navigate } from "react-router-dom";
import RouteContainer from "../components/core/RouteContainer";
import MasterLayout from "../components/layouts/MasterLayout";

const SettingsPage = lazy(() => import("@modules/settings/SettingsPage"));
const ProfilePage = lazy(() => import("@modules/profile/ProfilePage"));
const LeadPage = lazy(() => import("@modules/leads/LeadPage"));
const CalendaPage = lazy(() => import("@modules/calendar/CalendarPage"));
const ErrorPage = lazy(() => import("@modules/error/ErrorPage"));
const WorkFlowPage = lazy(() => import("@modules/workflow/WorkFlowPage"));
const SchedulePage = lazy(() => import("@modules/schedules/SchedulePage"));
const ReportsPage = lazy(() => import("@modules/reports/ReportsPage"));
const RemindersPage = lazy(() => import("@modules/reminders/RemindersPage"));
const BookingsPage = lazy(() => import("@modules/bookings/BookingsPage"));
const MessengerPage = lazy(() => import("@modules/messenger/MessengerPage"));
const MarketingPage = lazy(() => import("@modules/marketing/MarketingPage"));

const paths = [
  { path: "/auth/*", element: <Navigate to="/dashboard" /> },
  { path: "/dashboard/*", element: <DashboardPage /> },
  { path: "/error/*", element: <ErrorPage code={404} /> },
  { path: "/leads/*", element: <LeadPage /> },
  { path: "/calendar/*", element: <CalendaPage /> },
  { path: "/settings/*", element: <SettingsPage /> },
  { path: "/profile/*", element: <ProfilePage /> },
  { path: "/workflow/*", element: <WorkFlowPage /> },
  { path: "/schedules/*", element: <SchedulePage /> },
  { path: "/reports/*", element: <ReportsPage /> },
  { path: "/reminders/*", element: <RemindersPage /> },
  { path: "/messenger/*", element: <MessengerPage /> },
  { path: "/booking/*", service: "booking", element: <BookingsPage /> },
  { path: "/marketing/*", service: "marketing", element: <MarketingPage /> },
];

export default function PrivateRoutes() {
  return <RouteContainer paths={paths} layout={<MasterLayout />} />;
}
