import AppIcon from "@components/AppIcon";
import PH from "libphonenumber-js";
import { useContext, useEffect, useRef, useState } from "react";
import COUNTRIES from "src/config/countries";
import { AppContext } from "src/context/AppContext";
import fullSearch from "src/helpers/fullSearch";

export default function AppPhoneInput({ value, onChange, className, ...props }) {
  /** */

  const {
    CM: { profile },
  } = useContext(AppContext);

  // Menu Visibility
  const [menu, setMenu] = useState(false);

  // Selected Country
  const [selected, setSelected] = useState(COUNTRIES.find(({ ISO }) => ISO === profile?.default?.country));

  const [index, setIndex] = useState(0);

  // Input Search
  const [search, setSearch] = useState(undefined);

  // Filered Countries
  const _countries = fullSearch(COUNTRIES, search);

  // Input Field References
  const inputRef = useRef();
  const searchRef = useRef();

  // Menu Ref
  const menuRef = useRef();

  // Menu Items Ref
  const itemRefs = useRef([]);

  // Rendered Phone
  const phone = Boolean(value) ? PH(value) : undefined;

  // Value Listener
  useEffect(() => {
    if (phone) setSelected(COUNTRIES.find(({ code }) => code === phone?.countryCallingCode));
    else if (!Boolean(value)) setSelected(undefined); // Empty Value -> Reset Selected
  }, [value]);

  // Initial Default Value
  useEffect(() => {
    if (!value) _handleSelect(COUNTRIES.find(({ ISO }) => ISO === profile?.default?.country));
  }, []);

  // Menu Listeners
  useEffect(() => {
    const handleClose = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setMenu(false);
        setSearch(undefined);
      }
    };
    document.addEventListener("mousedown", handleClose);
    return () => {
      document.removeEventListener("mousedown", handleClose);
    };
  }, [menuRef]);

  // Keyboard Events
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (!menu) return;

      switch (event.key) {
        case "ArrowDown":
          setIndex((prevIndex) => (prevIndex < _countries.length - 1 ? prevIndex + 1 : _countries.length - 1));
          break;
        case "ArrowUp":
          setIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : 0));
          break;
        case "Enter":
          if (index !== -1) _handleSelect(_countries[index]);
          break;
        default:
          break;
      }
    };

    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [menu, index, _countries]);

  // Focus Events
  useEffect(() => {
    if (index !== -1 && itemRefs.current[index]) {
      itemRefs.current[index].scrollIntoView({
        behavior: "smooth",
        block: "nearest",
      });
    }
  }, [index]);

  const _handleToggleMenu = () => {
    if (menu) {
      setMenu(false);
      setSearch(undefined);
    } else {
      if (phone) return; // Valid Phone Already Identified
      setMenu(true);
      searchRef.current?.focus();
      setIndex(0);
    }
  };

  const _handleSelect = (country) => {
    if (!country) return;
    setSelected(country);
    if (value?.length > 3) onChange(`+${country?.code}${value ?? ""}`);
    else onChange(`+${country?.code}`);
    inputRef?.current?.focus();
    setMenu(false);
  };

  return (
    <div ref={menuRef} className="relative">
      <div className="flex items-center h-12 rounded-sm border border-gray-200">
        <div
          className="flex items-center h-full space-x-2 cursor-pointer bg-gray-50 hover:bg-gray-100 border-r px-4"
          onClick={() => !phone && _handleToggleMenu()}
        >
          {selected && <span>{selected?.flag}</span>}
          {!selected && <AppIcon code="fa-globe" className="w-4 h-4" />}
        </div>
        <input
          ref={inputRef}
          value={value}
          onChange={(event) => onChange(event.target.value)}
          type={props?.type ?? "text"}
          className="border-none focus:ring-0 flex-grow"
          {...props}
        />
        <span className="mx-2 text-sm text-gray-500">{phone?.formatInternational()}</span>
        {value && (
          <div className="flex items-center justify-center h-full px-4 hover:text-red-500 cursor-pointer" onClick={() => onChange("")}>
            <AppIcon code="fa-times" className="w-4 h-4" />
          </div>
        )}
      </div>

      {menu && (
        <div className="top-full absolute flex flex-col mt-2 p-2 space-y-2 h-48 overflow-auto w-full z-10 border shadow-sm bg-white">
          <input ref={searchRef} className="border-none bg-gray-50" autoFocus value={search} onChange={(e) => setSearch(e.target.value)} />

          {_countries?.map((country, _index) => {
            const _active = country.code === selected?.code || index === _index;
            return (
              <div
                key={_index}
                ref={(el) => (itemRefs.current[_index] = el)}
                className={`flex items-center space-x-2 p-2 cursor-pointer rounded-sm ${_active ? "bg-blue-500" : "bg-white hover:bg-gray-50"}`}
                onClick={() => _handleSelect(country)}
              >
                <span>{country.flag}</span>
                <span className={`text-sm ${_active ? "text-white" : "text-gray-700"}`}>{country.name}</span>
                <span className={`text-sm ${_active ? "text-white" : "text-gray-500"}`}>(+{country.code})</span>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
}
