import clx from "classnames";
import { useMemo, useState } from "react";
import { useIntl } from "react-intl";
import MediaPickerModal from "./modals/MediaPickerModal";

export default function AppMediaPicker({ value, onChange, form, multiple = false, leadID, ...props }) {
  const [modal, setModal] = useState({ enabled: false });
  const intl = useIntl();
  const renderModal = useMemo(
    () => (
      <MediaPickerModal
        show={modal.enabled}
        onClose={() => setModal({ enabled: false })}
        defaultSelected={multiple ? value ?? [] : [value]}
        onSelect={(mediaIDs) => {
          onChange(multiple ? mediaIDs : mediaIDs?.[0]);
          setModal({ enabled: false });
        }}
        leadID={leadID}
        multiple={multiple}
      />
    ),
    [modal.enabled]
  );
  return (
    <>
      <div
        className={clx(
          `cursor-pointer flex items-center justify-start border p-2  hover:bg-gray-50 text-base`,
          { "": value },
          { "text-gray-400": !value },
          props.className
        )}
        onClick={() => setModal({ enabled: true })}
      >
        {value ? <span>{multiple ? value?.length : 1} Media Selected</span> : <span>{intl.formatMessage({ id: props.placeholder ?? "-" })}</span>}
      </div>
      {renderModal}
    </>
  );
}
