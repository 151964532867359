import AppIcon from "@components/AppIcon";
import clx from "classnames";
import { memo } from "react";
const BottomTabItem = ({ icon, active, notifications, onClick }) => {
  return (
    <div
      onClick={onClick}
      className={clx("flex h-16 w-full items-center justify-center cursor-pointer relative", {
        "bg-gray-100 ": active,
        "bg-white hover:bg-gray-100 text-gray-600 ": !active,
      })}
    >
      <AppIcon code={icon} className="w-6" aria-hidden="true" />
      {notifications && <span className="w-4 h-4 text-blue-500 absolute top-2 right-2">●</span>}
    </div>
  );
};
export default memo(BottomTabItem);
