import queryString from "query-string";
import { useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";

export default function AppSearch({ inputClassName, ...props }) {
  const navigate = useNavigate();
  const URLParams = queryString.parse(useLocation().search);
  const inputRef = useRef(null);

  // Initial Search Value on Mounting
  useEffect(() => {
    if (URLParams.search) inputRef.current.value = URLParams.search;
  }, []);

  return (
    <div {...props}>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          const search = e.target?.search?.value;
          if (search !== URLParams.search) navigate(`?${queryString.stringify({ ...URLParams, search, ...(URLParams.page > 1 && { page: 1 }) })}`);
        }}
      >
        <input ref={inputRef} name="search" type="text" className={`form ${inputClassName}`} autoComplete="off" placeholder="Search & Press Enter" />
      </form>
    </div>
  );
}
