import { IntlProvider } from "react-intl";

import enMessages from "./messages/en.json";
import trMessages from "./messages/tr.json";

import { AppContext } from "@context/AppContext";
import { useContext } from "react";

const allMessages = {
  en: enMessages,
  tr: trMessages,
  // de: deMessages,
  // es: esMessages,
  // az: azMessages,
};

export default function I18nProvider({ children }) {
  const { locale = "en" } = useContext(AppContext);
  const messages = allMessages[locale];
  return (
    <IntlProvider locale={locale} messages={messages} onError={() => {}}>
      {children}
    </IntlProvider>
  );
}
