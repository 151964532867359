import AppModal from "@components/AppModal";
import CrudButtons from "@components/CrudButtons";
import { useMemo, useState } from "react";
import text from "src/helpers/text";
import { AppInput } from ".";
import AppTextArea from "./AppTextArea";

export default function AppTextOptions({ show, onClose, onSubmit }) {
  const [value, setValue] = useState(undefined);

  const _render = useMemo(() => {
    return value
      ?.split("\n")
      ?.map((item) => {
        const _item = item.trim();
        return { label: _item, value: text.slugify(_item) };
      })
      .filter(({ label, value }) => Boolean(label) && Boolean(value));
  }, [value]);

  return (
    <AppModal
      show={show}
      scrollable
      onHide={onClose}
      header="T.RENDER_OPTIONS_FROM_TEXT"
      body={
        <div className="flex flex-col space-y-4">
          <AppTextArea placeholder="Paste Here" rows="10" value={value} onChange={(value) => setValue(value)} />
          <div className="flex flex-col space-y-2">
            {_render?.map(({ label, value }) => {
              return (
                <div className="flex items-center space-x-2">
                  <AppInput disabled value={label} />
                  <AppInput disabled value={value} />
                </div>
              );
            })}
          </div>
        </div>
      }
      footer={<CrudButtons onCancell={onClose} onSubmit={() => onSubmit(_render)} />}
    />
  );
}
