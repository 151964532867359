export default function ServiceDenied({ service }) {
  return (
    <>
      <div className="flex flex-col flex-center p-10 bg-white">
        <span className="fs-4 uppercase ls-1 text-red-500">Service Disabled</span>
        <span className=" mt-3 ">
          This requested service <code className="text-gray-600">{service}</code> is not active.
        </span>
        <span className="mt-2">[Please call the the system adminstrator for further information.]</span>
      </div>
    </>
  );
}
