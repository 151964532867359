import debounce from "lodash.debounce";
import AsyncSelect from "react-select/async";
export default function AppAsyncSelect({ onChange, options = [], loading = false, searchFunction = async () => {}, ...props }) {
  const customStyles = {
    control: (props) => ({
      ...props,
      border: "1px solid #e4e6ef",
      borderRadius: 0,
    }),
  };

  const handleSearch = debounce(searchFunction, 300);

  return (
    <AsyncSelect
      isDisabled={props.disabled}
      styles={customStyles}
      // instanceId={field?.name}
      onChange={onChange}
      {...props}
      cacheOptions
      defaultOptions
      loadOptions={handleSearch}
    />
  );
}
