import clx from "classnames";
import { memo } from "react";
import AppTooltip from "./AppTooltip";
import ProtectedMedia from "./ProtectedMedia";
import "./tooltip.css";

const AppAvatar = ({ mediaID, className, placeholder, tooltip }) => {
  return (
    <AppTooltip tooltip={tooltip}>
      {mediaID ? (
        <ProtectedMedia mediaID={mediaID} className={`tooltip-anchor ${className}`} />
      ) : (
        <span className={clx("tooltip-anchor inline-flex items-center justify-center bg-gray-100 text-gray-400 text-base", className)}>
          <span className="font-medium leading-none tracking-wider">
            {placeholder
              ?.split(" ")
              ?.slice(0, 2)
              ?.map((x) => x[0])
              .join("")}
          </span>
        </span>
      )}
    </AppTooltip>
  );
};

export default memo(AppAvatar);
