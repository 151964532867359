const listeners = {};
const EventService = {
  on: (events, fn) => {
    events?.forEach((code) => {
      if (!listeners[code]) listeners[code] = new Set();
      listeners[code].add(fn);
    });
  },
  off: (events, fn) => {
    events?.forEach((code) => {
      if (listeners[code]) listeners[code].delete(fn);
    });
  },
  emit: (code, payload) => {
    listeners[code]?.forEach((fn) => fn(payload));
  },
};

export default EventService;
