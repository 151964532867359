import { Field, useFormikContext } from "formik";
import { useIntl } from "react-intl";
import AppInput from "./AppInput";
import AppSelect from "./AppSelect";
import AppSwitch from "./AppSwitch";
import RowInput from "./RowInput";

export default function AppWorkingHours({ className }) {
  const intl = useIntl();
  const { values } = useFormikContext();
  return (
    <div className={`flex flex-col space-y-2`}>
      {[
        { label: "L.SATURDAY", code: "sat" },
        { label: "L.SUNDAY", code: "sun" },
        { label: "L.MONDAY", code: "mon" },
        { label: "L.TUESDAY", code: "tue" },
        { label: "L.WEDNESDAY", code: "wed" },
        { label: "L.THURSDAY", code: "thu" },
        { label: "L.FRIDAY", code: "fri" },
      ].map(({ label, code }) => (
        <div className="grid grid-cols-12 gap-2" key={code}>
          <div className="col-span-6 flex items-center space-x-2">
            <Field name={`works.${code}.enabled`} component={RowInput} FormElement={AppSwitch} />
            <span>{intl.formatMessage({ id: label })}</span>
          </div>
          <div className="col-span-6">
            <div className="grid grid-cols-12 gap-2" style={{ visibility: values?.works?.[code]?.enabled ? "visible" : "hidden" }}>
              <div className="col-span-full lg:col-span-4">
                <Field name={`works.${code}.start`} component={RowInput} FormElement={AppInput} type="time" />
              </div>
              <div className="col-span-full lg:col-span-4">
                <Field name={`works.${code}.end`} component={RowInput} FormElement={AppInput} type="time" />
              </div>
              <div className="col-span-full lg:col-span-4 flex items-center space-x-2">
                <Field
                  name={`works.${code}.mario`}
                  component={RowInput}
                  FormElement={AppSelect}
                  className="w-full"
                  placeholder="L.OVERTIME"
                  options={[
                    { label: "L.ENABLED", value: true },
                    { label: "L.DISABLED", value: false },
                  ]}
                  locale
                />
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}
