import { useRef } from "react";
import toggleInclusion from "../../utilities/toggleInclusion";
import AppIcon from "../AppIcon";

const TagBagde = ({ value, onClick }) => {
  return (
    <div className="flex items-center bg-gray-100 hover:bg-gray-200 space-x-2 text-gray-600 px-2 py-1 cursor-pointer " onClick={onClick}>
      <span className="block">{value}</span>
      <AppIcon code="fa-times" className="icon-xs" />
    </div>
  );
};
export default function AppTagInput({ value, onChange, meta, ...props }) {
  const inputRef = useRef();
  const handleValue = (text) => onChange(toggleInclusion(value ?? [], text));
  const handleKey = (event) => {
    if ((event.key === "Enter" || event.key === "Tab") && inputRef.current.value) {
      handleValue(inputRef.current.value);
      inputRef.current.value = "";
    }
  };

  return (
    <div className={`flex flex-col border ${props?.className}`}>
      <input onKeyDown={handleKey} type="text" ref={inputRef} placeholder={props.placeholder} className="form border-none" />
      {value?.length > 0 && (
        <div className="flex items-center p-2 flex-wrap space-x-2 border-t">
          {value?.map((value, _index) => (
            <TagBagde value={value} key={_index} onClick={() => handleValue(value)} />
          ))}
        </div>
      )}
    </div>
  );
}
