import ENDPOINTS from "@config/endpoints";
import { AuthContext } from "@context/AuthContext";
import axios from "axios";
import { useContext, useEffect } from "react";
import { useMutation } from "react-query";

export default function useRate() {
  const { isLogedIn } = useContext(AuthContext);

  // Get Exchange Rates Mutation
  const GRM = useMutation(() => axios.get(`${ENDPOINTS.company}/rates`));

  // Get Rate Value
  const getRate = (_id) => GRM.data?.find((x) => x._id === _id);

  // Calculate Price
  const calculate = (value = 0, fromCode, toCode) => {
    if (fromCode === toCode) return value;
    const fromRate = getRate(fromCode) ?? { value: 1 };
    const toRate = getRate(toCode) ?? { value: 0 };
    const coeff = toRate.value / fromRate.value;
    return Math.ceil(coeff * value * 100) / 100;
  };

  // Get Rates
  useEffect(() => {
    if (isLogedIn) GRM.mutate();
  }, [isLogedIn]);

  return { methods: { getRate, calculate }, rates: GRM.data ?? [] };
}
