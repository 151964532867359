import { Dialog, Transition } from "@headlessui/react";
import clx from "classnames";
import { Fragment } from "react";
import { useIntl } from "react-intl";
import CloseButton from "../CloseButton";

export default function AppDrawer({ title, body, bodyClassName, footer, show, onClose }) {
  const intl = useIntl();
  return (
    <Transition.Root show={show} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={onClose}>
        <div className="fixed inset-0" />
        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full lg:pl-10">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-300 sm:duration-300"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-300 sm:duration-300"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="pointer-events-auto w-screen max-w-xl">
                  <div className="flex h-full pb-16 md:pb-0 flex-col bg-white shadow-xl">
                    <div className="flex items-center justify-between px-4 py-2 border-b h-16">
                      <Dialog.Title className="text-base ">{intl.formatMessage({ id: title ?? "-" })}</Dialog.Title>
                      <CloseButton onClick={onClose} />
                    </div>
                    <div className="flex min-h-0 flex-1 flex-col overflow-y-scroll">
                      <div className={clx("relative flex-1 w-full h-full", { "py-2 px-4": !bodyClassName })}>{body}</div>
                    </div>
                    {footer && <div className="px-2 py-2 border-t bg-gray-100 flex items-center justify-end">{footer}</div>}
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
