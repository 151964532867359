import AppButton from "@components/AppButton";
import { useContext, useRef } from "react";
import { CallContext } from "src/context/CallContext";

const NumPadButton = ({ value, className, onClick, ...props }) => {
  return (
    <div className={`h-16 flex items-center justify-center cursor-pointer hover:bg-gray-100 text-xl ${className}`} onClick={() => onClick(value)} {...props}>
      {value}
    </div>
  );
};

export default function NumPad({ visible }) {
  const inputRef = useRef(null);

  const { callManager } = useContext(CallContext);
  const { call } = callManager;

  // Add Characters
  const handleAddCharacter = (value) => {
    if (!Boolean(value)) return;
    if (inputRef.current) inputRef.current.value = inputRef.current.value + value; // Update Value
    return call?.instance?.sendDigits(String(value));
  };

  // Remove Character
  const handleRemoveChar = () => {
    if (inputRef.current) inputRef.current.value = inputRef.current.value.slice(0, -1);
  };

  return (
    <div className="flex flex-col transition-all" style={{ display: visible ? "block" : "none" }}>
      <div className="flex items-center space-x-2 p-2 bg-gray-100 border-b">
        <input ref={inputRef} disabled className="form border-none text-center flex-grow bg-gray-50 rounded-sm" />
        <AppButton icon="fa-backspace" mode="outline" className="text-gray-400 hover:text-gray-600" onClick={handleRemoveChar} />
      </div>
      <div className="grid grid-cols-3 p-6">
        {[...Array(9).keys()]
          .map((index) => index + 1)
          .map((number) => (
            <NumPadButton value={number} key={number} className="col-span-1" onClick={handleAddCharacter} />
          ))}
        <NumPadButton className="col-span-1" value="*" onClick={handleAddCharacter} />
        <NumPadButton className="col-span-1" value="0" onClick={handleAddCharacter} />
        <NumPadButton className="col-span-1" value="#" onClick={handleAddCharacter} />
      </div>
    </div>
  );
}
