import ENDPOINTS from "@config/endpoints";
import { AuthContext } from "@context/AuthContext";
import axios from "axios";
import { useContext, useEffect } from "react";
import { useMutation } from "react-query";

export default function useProfile() {
  const GPM = useMutation(() => axios.get(`${ENDPOINTS.user}/me/profile`));

  const { isLogedIn } = useContext(AuthContext);

  useEffect(() => {
    if (isLogedIn) GPM.mutate();
  }, [isLogedIn]);

  const refresh = () => GPM.mutate();

  return { profile: GPM.data ?? {}, methods: { refresh }, isLoading: GPM.isLoading };
}
