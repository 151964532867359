import clx from "classnames";
import AppIcon from "./AppIcon";
import Spinner from "./Spinner";
import Locale from "./core/Locale";

const CardHeader = ({ title, loading, icon, toolbar, locale }) => {
  return (
    <div className="pl-4 pr-2 h-16 flex items-center flex-shrink-0">
      <div className="flex items-center space-x-2 truncate">
        {icon && <AppIcon className="w-4 h-4" code={icon} />}
        {typeof title === "string" ? <span className="card-title">{locale ? <Locale code={title} /> : title}</span> : title}
      </div>
      <div className="flex-grow flex justify-end items-center">{loading ? <Spinner className="w-4 h-4 mx-2" /> : toolbar}</div>
    </div>
  );
};

export default function AppCard({
  title,
  icon,
  subtitle,
  toolbar,
  body,
  bodyClassName,
  scrollable = false,
  footerClassName = "",
  footer,
  locale = true,
  loading = false,
  className = "",
}) {
  return (
    <div className={`flex flex-col bg-white border rounded-sm ${className}`}>
      {title && <CardHeader loading={loading} title={title} subtitle={subtitle} icon={icon} locale={locale} toolbar={toolbar} />}
      {!loading && body && (
        <div className={`flex flex-col flex-grow border-t ${!bodyClassName && "p-2"} ${scrollable && "overflow-x-auto"} ${bodyClassName}`}>{body}</div>
      )}
      {!loading && footer && <div className={clx("border-t flex items-center", { "p-2 justify-end": !footerClassName }, footerClassName)}>{footer}</div>}
    </div>
  );
}
