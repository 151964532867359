import { Suspense } from "react";
import TopBarProgress from "react-topbar-progress-indicator";

TopBarProgress.config({
  barThickness: 1,
  shadowBlur: 5,
});

export default function SuspensedView({ children }) {
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>;
}
