import { AppContext } from "@context/AppContext";
import { AuthContext } from "@context/AuthContext";
import { useContext } from "react";
import { Outlet, Route, Routes } from "react-router-dom";
import AccessDenied from "./AccessDenied";
import ServiceDenied from "./ServiceDenied";
import SuspensedView from "./SuspensedView";

export default function RouteContainer({ paths, layout = <Outlet /> }) {
  const {
    CM: { isServiceEnabled },
  } = useContext(AppContext);
  const { isAccessible } = useContext(AuthContext);
  return (
    <Routes>
      <Route element={layout}>
        {paths.map(({ code, service, path, element }, _index) => (
          <Route
            key={_index}
            path={path}
            element={
              <SuspensedView>
                {!isAccessible(code) ? <AccessDenied code={code} /> : !isServiceEnabled(service) ? <ServiceDenied service={service} /> : element}
              </SuspensedView>
            }
          />
        ))}
      </Route>
      {/* <Route path="*" element={<Navigate to="/error/404" />} /> */}
    </Routes>
  );
}
