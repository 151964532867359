import AppButton from "@components/AppButton";
import { AppLayoutContext } from "@context/AppLayoutContext";
import { useCallback, useContext } from "react";
import { NotificationContext } from "../../../context/NotificationContext";
import AppDrawer from "../AppDrawer";
import NotificationItem from "./components/NotificationItem";

export default function NotificationsDrawer({}) {
  const { notification: ND } = useContext(AppLayoutContext);
  const { notifications, methods } = useContext(NotificationContext);

  const _handleClick = useCallback((UUID, code, payload) => {
    ND.close(); // Close Drawer
    methods.seen(UUID); // Set Notification "SEEN"
    return methods.click(code, payload);
  }, []);

  return (
    <AppDrawer
      show={ND.enabled}
      onClose={() => ND.close()}
      title="T.NOTIFICATIONS"
      body={
        <div className="absolute inset-0 overflow-y-auto">
          {notifications?.map((notification, _index) => (
            <NotificationItem {...notification} key={_index} onClick={_handleClick} />
          ))}
        </div>
      }
      footer={<AppButton title="BTN.CLEAR_ALL" onClick={() => methods.removeAll()} />}
    />
  );
}
