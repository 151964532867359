import ENDPOINTS from "@config/endpoints";
import { AuthContext } from "@context/AuthContext";
import axios from "axios";
import { useContext, useEffect } from "react";
import { useMutation } from "react-query";

export default function useGlobal() {
  const countries = useMutation(() => axios.get(`${ENDPOINTS.misc}/countries`));
  const languages = useMutation(() => axios.get(`${ENDPOINTS.misc}/languages`));
  const timezones = useMutation(() => axios.get(`${ENDPOINTS.misc}/timezones`));
  const sources = useMutation(() => axios.get(ENDPOINTS.source));
  const labels = useMutation(() => axios.get(ENDPOINTS.label));
  const actions = useMutation(() => axios.get(ENDPOINTS.action));
  const levels = useMutation(() => axios.get(ENDPOINTS.level));
  const notificationCodes = useMutation(() => axios.get(`${ENDPOINTS.notification}/codes`));
  const rates = useMutation(() => axios.get(ENDPOINTS.rate));
  const clinics = useMutation(() => axios.get(ENDPOINTS.clinic));
  const treatments = useMutation(() => axios.get(ENDPOINTS.treatment));
  const personnel = useMutation(() => axios.get(ENDPOINTS.staff));
  const users = useMutation(() => axios.get(ENDPOINTS.user, { params: { status: "all" } }));
  const groups = useMutation(() => axios.get(ENDPOINTS.group));
  const categories = useMutation(() => axios.get(ENDPOINTS.category));
  const fields = useMutation(() => axios.get(ENDPOINTS.field));
  const mediaTags = useMutation(() => axios.get(ENDPOINTS.mediaTag));
  const taxGroups = useMutation(() => axios.get(ENDPOINTS.tax));
  const tags = useMutation(() => axios.get(ENDPOINTS.tag));

  const allMutations = [
    countries,
    languages,
    timezones,
    sources,
    labels,
    actions,
    levels,
    notificationCodes,
    rates,
    clinics,
    treatments,
    personnel,
    users,
    groups,
    categories,
    fields,
    mediaTags,
    taxGroups,
    tags,
  ];

  const { isLogedIn } = useContext(AuthContext);

  useEffect(() => {
    if (isLogedIn) for (let m of allMutations) m.mutate();
  }, [isLogedIn]);

  return {
    countries: countries.data ?? [],
    languages: languages.data ?? [],
    timezones: timezones.data ?? [],
    sources: sources.data ?? [],
    labels: labels.data ?? [],
    actions: actions.data ?? [],
    levels: levels.data ?? [],
    notificationCodes: notificationCodes.data ?? [],
    rates: rates.data ?? [],
    clinics: clinics.data ?? [],
    treatments: treatments.data ?? [],
    personnel: personnel.data ?? [],
    users: {
      all: users?.data ?? [],
      active: (users?.data ?? [])?.filter(({ active }) => active),
      deactive: (users?.data ?? [])?.filter(({ active }) => !active),
    },
    groups: groups.data ?? [],
    categories: categories.data ?? [],
    fields: fields.data ?? [],
    mediaTags: mediaTags.data ?? [],
    taxGroups: taxGroups.data ?? [],
    tags: tags.data ?? [],
    isLoading: allMutations.some((x) => x.isLoading),
  };
}
