const _isObject = (value) => typeof value === "object" && !Array.isArray(value) && value !== null;

const _stringMatch = (a, b) => a.toString().toUpperCase().indexOf(b.toString().toUpperCase()) !== -1;

const _find = (object, search) => {
  let found = false;
  Object.keys(object).forEach((key) => {
    if (found) return;
    const value = object[key];
    if (typeof value === "string") found = _stringMatch(value, search);
    else if (_isObject(value)) found = _find(value, search);
    else found = false;
  });
  return found;
};

export default function fullSearch(items = [], search = undefined) {
  if (!search) return items;
  return items.filter((item) => _find(item, search));
}
