import useCompany from "@hooks/useCompany";
import useGlobal from "@hooks/useGlobal";
import useProfile from "@hooks/useProfile";
import useRate from "@hooks/useRate";
import useSocket from "@hooks/useSocket";
import { createContext, useState } from "react";
import LoadingScreen from "../components/screens/LoadingScreen";

const AppContext = createContext();
const { Provider } = AppContext;

const AppConfigProvider = ({ children }) => {
  // App UI Locale
  const [locale, setLocale] = useState(localStorage.getItem("locale") ?? "en");

  // Update App UI Locale
  const changeLocale = (code) => {
    localStorage.setItem("locale", code);
    setLocale(code);
    return;
  };

  const PM = useProfile();
  const GM = useGlobal();
  const CM = useCompany();
  useSocket(); // Initiate SocketIO
  return (
    <Provider
      value={{
        PM, // Profile Information
        RM: useRate(), // Currencies
        CM, // Company Details
        GM, // Global Values
        locale,
        changeLocale,
      }}
    >
      {GM.isLoading || CM.isLoading || PM.isLoading ? <LoadingScreen /> : children}
    </Provider>
  );
};
export { AppConfigProvider, AppContext };
