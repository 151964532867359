import { useNavigate } from "react-router-dom";
import AppButton from "./AppButton";
import clx from "classnames";
export default function CrudButtons({
  onSubmit = () => {},
  onCancell,
  loading = false,
  disabled = false,
  title = "BTN.SAVE",
  className = "justify-between w-full",
}) {
  const navigate = useNavigate();
  return (
    <div className={clx("flex items-center space-x-2", className)}>
      <AppButton onClick={() => (onCancell ? onCancell() : navigate(-1))} title="BTN.CANCEL" />
      <AppButton mode="primary" title={title} loading={loading} disabled={disabled} onClick={onSubmit} />
    </div>
  );
}
