export default function AccessDenied({ code }) {
  return (
    <>
      <div className="flex flex-col items-center justify-center h-full bg-white">
        <span className="fs-4 uppercase ls-1 text-red-500">Access Denied</span>
        <span className=" mt-3 ">
          The required permission <code className="text-gray-600">{code}</code> is not granted to you.
        </span>
        <span className="mt-2">[Please contact the system adminstrator for more details.]</span>
      </div>
    </>
  );
}
