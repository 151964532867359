import AppAvatar from "@components/AppAvatar";
import { memo } from "react";
import { useIntl } from "react-intl";
import DateFormat from "../../../DateFormat";
import NotificationBadge from "./NotificationBadge";

const NotificationItem = ({ UUID, seen, code, display, payload, updatedAt, count, onClick }) => {
  const intl = useIntl();
  return (
    <div className="flex items-center border-b h-16 px-4 space-x-2 hover:bg-gray-100 cursor-pointer" onClick={() => onClick(UUID, code, payload)}>
      <AppAvatar className="w-8 h-8 rounded-full" placeholder={`${display?.firstName ?? ""} ${display?.lastName ?? ""}`} />
      <div className="flex flex-col space-y-1 w-36">
        <span className="leading-none text-sm text-gray-700 truncate whitespace-nowrap">{display?.name}</span>
        <span className="leading-none text-sm text-gray-500">{intl.formatMessage({ id: code ?? "-" })}</span>
      </div>
      <div className="flex flex-grow items-center justify-end space-x-2">
        {updatedAt && (
          <span className="text-gray-500 text-sm whitespace-nowrap">
            <DateFormat date={updatedAt} />
          </span>
        )}
        {!seen && <NotificationBadge value={count} />}
      </div>
    </div>
  );
};

export default memo(NotificationItem);
