import AppAvatar from "@components/AppAvatar";
import { memo } from "react";
import useLocale from "src/hooks/useLocale";

const ElasticSearchItem = ({ lead, onClick }) => {
  const { t } = useLocale();
  return (
    <div onClick={() => onClick(lead?._id)} className="flex items-center space-x-2 p-4 bg-gray-50 hover:bg-gray-100 rounded-md cursor-pointer">
      <AppAvatar mediaID={lead?.thumbnail} placeholder={`${lead?.firstName} ${lead?.lastName}`} className="w-8 h-8 flex-shrink-0 rounded-sm" />
      <span className="capitalize font-medium text-gray-500">
        {lead?.firstName ?? ""} {lead?.lastName ?? ""}
      </span>
      <div className="flex items-center justify-end flex-1">
        <span className="capitalize text-center font-medium" style={{ color: lead?.status?.color }}>
          {t(lead?.status?.name)}
        </span>
      </div>
    </div>
  );
};

export default memo(ElasticSearchItem, (prevProps, nextProps) => prevProps.lead?._id === nextProps.lead?._id);
