import ENDPOINTS from "@config/endpoints";
import { AuthContext } from "@context/AuthContext";
import axios from "axios";
import { useContext, useEffect } from "react";
import { useMutation } from "react-query";

export default function useCompany() {
  const GCM = useMutation(() => axios.get(`${ENDPOINTS.company}/basic`));

  const { isLogedIn } = useContext(AuthContext);

  useEffect(() => {
    if (isLogedIn) GCM.mutate();
  }, [isLogedIn]);

  const refresh = () => GCM.mutate();

  // Company Profile
  const profile = GCM.data ?? {};

  // Company Services
  const services = {
    messenger: ["facebook", "whatsapp"].some((service) => profile?.services?.includes(service)),
    call: profile?.services?.includes("call"),
    scheduler: profile?.services?.includes("scheduler"),
    booking: profile?.services?.includes("booking"),
    marketing: profile?.services?.includes("marketing"),
    timeline: profile?.services?.includes("timeline"),
    associates: profile?.services?.includes("associates"),
  };

  // Service Accessible
  const isServiceEnabled = (codes) => {
    if (!codes) return true;
    if (Array.isArray(codes)) return codes?.some((code) => services?.[code]);
    else return services?.[codes];
  };

  return { profile, services, methods: { refresh }, isServiceEnabled, isLoading: GCM.isLoading };
}
