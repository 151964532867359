import AppButton from "@components/AppButton";
import AppModal from "@components/AppModal";
import { useState } from "react";

export default function VersionAlertModal({ version, onClose }) {
  /** */

  const [loading, setLoading] = useState(false);

  const handleUpdate = (values) => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      window.location.reload();
    }, 500);
  };

  return (
    <AppModal
      show={true}
      header="T.NEW_VERSION_TITLE"
      closeable={false}
      body={
        <div className="flex flex-col space-y-4">
          <div className="flex flex-col space-y-2">
            <span className="font-semibold">{version?.code}</span>
            <ul className="text-gray-500">
              {version?.details?.split("|")?.map((item, _index) => (
                <li key={_index}>{item}</li>
              ))}
            </ul>
          </div>
          <AppButton mode="primary" title="T.UPDATE" onClick={handleUpdate} loading={loading} />
        </div>
      }
    />
  );
}
