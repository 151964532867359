import AppAvatar from "@components/AppAvatar";
import AppButton from "@components/AppButton";
import AppCard from "@components/AppCard";
import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { useMutation } from "react-query";
import { Link } from "react-router-dom";
import ENDPOINTS from "src/config/endpoints";
import { CallContext } from "src/context/CallContext";
import DialPad from "./components/NumPad";

export default function CallAlert() {
  const {
    callManager: { call, device },
    _isEnabled,
  } = useContext(CallContext);

  const [showDialPad, setShowDialPad] = useState(false);

  const callID = call?.callID;

  // Get Call Whois Mutation
  const GWM = useMutation(() => axios.get(`${ENDPOINTS.whois}/${callID}`));

  const lead = GWM.data?.lead ?? {};

  // Whois
  useEffect(() => {
    if (callID) GWM.mutate();
  }, [callID]);

  // Title Update
  useEffect(() => {
    if (call?.active) document.title = `Incoming Call From ${call?.displayedNumber}`;
    else document.title = "CRM 2.0";
  }, [call?.active]);

  if (!_isEnabled || !call?.active) return <></>;

  return (
    <AppCard
      className="absolute right-6 bottom-6 w-[360px] shadow-xl border rounded-sm"
      title={call?.direction === "inbound" ? "T.INCOMING_CALL" : "T.OUTGOING_CALL"}
      toolbar={<AppButton icon={showDialPad ? "fa-caret-down" : "fa-caret-up"} mode="icon" onClick={() => setShowDialPad(!showDialPad)} />}
      bodyClassName="p-0"
      body={
        <div className="flex flex-col">
          <div className="flex items-center justify-between p-4 border-b">
            <div className="flex items-center space-x-4">
              <Link to={`/leads/${lead?._id}/profile/overview`}>
                <AppAvatar placeholder={lead ? `${lead?.fistName} ${lead?.lastName}` : "?"} mediaID={lead?.thumbnail} className="w-12 h-12 rounded-full" />
              </Link>
              <div className="flex flex-col">
                <span className="font-medium ">
                  {lead?.firstName} {lead?.lastName}
                </span>
                <span className="text-sm font-normal text-gray-500">{call?.displayedNumber}</span>
              </div>
            </div>
            <div className="flex items-center space-x-2">
              <AppButton
                mode="light-danger"
                icon="fa-times"
                className="rounded-full"
                onClick={() => (call?.status === "pending" ? call?.instance?.reject() : device?.instance?.disconnectAll())}
              />
              {call?.direction === "inbound" && call?.status === "pending" && (
                <AppButton mode="success" icon="fa-phone" className="w-24" onClick={() => call?.instance?.accept()} />
              )}
            </div>
          </div>
          <DialPad visible={showDialPad} />
        </div>
      }
    />
  );
}
