import { AppLayoutContext } from "@context/AppLayoutContext";
import { useContext } from "react";
import { useIntl } from "react-intl";
import AppButton from "./AppButton";
import AppIcon from "./AppIcon";
import AppModal from "./AppModal";

const _getIcon = (mode) => {
  switch (mode) {
    case "danger":
      return "fa-exclamation-triangle";
    case "warning":
      return "fa-exclamation-circle";
    case "success":
      return "fa-check-circle";
    default:
      return "fa-info-circle ";
  }
};

const _getClassName = (mode) => {
  switch (mode) {
    case "danger":
      return "text-red-500";
    case "warning":
      return "text-amber-400";
    case "success":
      return "text-blue-500";
    default:
      return "text-gray-500";
  }
};

export default function AppDialog() {
  const intl = useIntl();
  const { dialog } = useContext(AppLayoutContext);
  const { enabled, mode, title, body, confirmButtonTitle, cancelButtonTitle, onConfirm, close } = dialog;
  return (
    <AppModal
      show={enabled}
      onHide={close}
      header={
        <div className="flex items-center ">
          <AppIcon code={_getIcon(mode)} className={`w-6 h-6 ${_getClassName(mode)}`} />
          <span className="ms-3">{intl.formatMessage({ id: title ?? "-" })}</span>
        </div>
      }
      body={typeof body === "string" ? intl.formatMessage({ id: body }) : body}
      footer={
        <div className="flex items-center justify-between w-full">
          <AppButton className="mr-3" title={cancelButtonTitle ?? "BTN.CANCEL"} onClick={close} />
          <AppButton
            mode="primary"
            title={confirmButtonTitle ?? "BTN.CONFIRM"}
            onClick={() => {
              close();
              onConfirm();
            }}
          />
        </div>
      }
    />
  );
}
