import { lazy, Suspense, useContext, useMemo } from "react";
import { CallContext } from "@context/CallContext";
import { ChatContext } from "@context/ChatContext";
import NotificationsDrawer from "@components/drawers/notification/NotificationDrawer";

const ChatDrawer = lazy(() => import("../drawers/messenger/ChatDrawer"));
const TwilioCallDrawer = lazy(() => import("../drawers/twilio-call/TwilioCallDrawer"));

export default function DrawersContainer() {
  const { _isEnabled: callIsEnabled } = useContext(CallContext);
  const { _isEnabled: chatIsEnabled } = useContext(ChatContext);

  return (
    <Suspense>
      {callIsEnabled && <TwilioCallDrawer />}
      {chatIsEnabled && <ChatDrawer />}
      <NotificationsDrawer />
    </Suspense>
  );
}
