import { DESKTOP_NOTIFICATION_CODES, SOCKET_API_URL } from "@config";
import { AuthContext } from "@context/AuthContext";
import { useContext, useEffect } from "react";
import io from "socket.io-client";
import EventService from "../services/event.service";

export default function useSocket() {
  const { token, isLogedIn } = useContext(AuthContext);

  let socket = undefined;

  useEffect(() => {
    if (!isLogedIn) return console.log("Not Logged In");
    const socket = io(SOCKET_API_URL, { auth: { token } });

    DESKTOP_NOTIFICATION_CODES.forEach((code) => socket.on(code, (payload) => EventService.emit(code, payload)));

    return () => {
      DESKTOP_NOTIFICATION_CODES.forEach((code) => socket.off(code)); // Remove Listeners
      socket.close(); // Close Socket
    };
  }, [isLogedIn]);

  return { socket };
}
