import { Route, Routes } from "react-router-dom";
import LoginPage from "./LoginPage";

export default function AuthPage() {
  return (
    <Routes>
      <Route path="login" element={<LoginPage />} />
      <Route path="logout" element={<>Logout</>} />
      <Route index element={<LoginPage />} />
    </Routes>
  );
}
